import React, {useState} from "react";
import {Form, Container, Row, Col} from "react-bootstrap";
import {toast} from "react-toastify";
import {BsImageFill} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import GeneriqueModal from "components/GeneriqueModal";
import ButtonCancel from "components/buttons/ButtonCancel";
import ButtonGreen from "components/buttons/ButtonGreen";
import {useLazyExtractOcrDataQuery} from "actions/orc/queries";
import {useUploadOcrFileMutation} from "actions/orc/mutations";

function InvoiceUploadModal({isOpen, onClose,refetch, dossier_id}) {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const navigate = useNavigate();

  // Initialize the RTK Query hooks
  const [
    uploadOcrFile,
    {isLoading: isUploading, isError: isUploadError, error: uploadError},
  ] = useUploadOcrFileMutation();
  const [
    triggerExtractOcrData,
    {isFetching: isExtracting, isError: isExtractError, error: extractError},
  ] = useLazyExtractOcrDataQuery();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.warning("Veuillez sélectionner un fichier.");
      return;
    }

    try {
      // Create FormData and append the file
      const formData = new FormData();
      formData.append("file", file);
      formData.append("dossier_client", dossier_id);

      // Upload the file
      const uploadResponse = await uploadOcrFile(formData).unwrap();
      const {id, file: fileUrl} = uploadResponse;

      if (!id) {
        toast.error("Erreur lors du téléchargement du fichier.");
        return;
      }

      // Trigger OCR extraction using the ID
      const ocrData = await triggerExtractOcrData(id).unwrap();
      console.log("OCR Data from API:", ocrData);

      if (ocrData) {
        refetch();
        onClose();
        navigate(`view-invoice/${id}/`, {state: {ocrId: id, dataFromOCR:ocrData, fileUrl}});
      } else {
        toast.error("Échec de l'extraction OCR.");
      }
    } catch (error) {
      toast.error("Erreur lors de l'envoi ou de l'extraction OCR.");
      console.error(error);
    }
  };

  return (
    <GeneriqueModal
      onClose={onClose}
      isOpen={isOpen}
      title="Ajouter une facture OCR">
      <Container fluid className="container-style py-3">
        <Form onSubmit={handleSubmit}>
          <div className="d-flex flex-column align-items-center gap-4">
            <Row className="w-100 mb-3">
              <Col sm={12}>
                <Form.Group controlId="formFile">
                  <Form.Label className="mb-2">
                    Sélectionner une facture
                  </Form.Label>
                  <Form.Control
                    type="file"
                    //accept="image/*"
                    onChange={handleFileChange}
                    className="rounded rounded-pill"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="w-100">
              <Col sm={12}>
                <div className="card text-center">
                  {filePreview ? (
                    <img
                      src={filePreview}
                      alt="Aperçu de la facture"
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        height: "300px",
                      }}
                    />
                  ) : (
                    <label htmlFor="formFile" style={{cursor: "pointer"}}>
                      <div
                        className="d-flex py-5 flex-column align-items-center justify-content-center"
                        style={{color: "#BDBDBD"}}>
                        <BsImageFill className="fs-1 pt-3" />
                        <p className="m-0 p-0 pb-3">Ajouter une facture</p>
                      </div>
                    </label>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={12} className="text-center">
                <div className="d-flex gap-3">
                  <ButtonCancel text="Annuler" onClick={onClose} />
                  <ButtonGreen
                    text={
                      isExtracting
                        ? "Extraction..."
                        : "Soumettre pour extraction" ||
                          (isUploading && "Chargement...")
                    }
                    type="submit"
                    disabled={!file || isUploading || isExtracting}
                  />
                </div>
              </Col>
            </Row>
            {(isUploading || isExtracting) && (
              <p className="d-none">Chargement...</p>
            )}
            {(isUploadError || isExtractError) && (
              <p className="text-danger">
                Erreur:{" "}
                {uploadError?.data?.message ||
                  extractError?.data?.message ||
                  uploadError?.error ||
                  extractError?.error}
              </p>
            )}
          </div>
        </Form>
      </Container>
    </GeneriqueModal>
  );
}

export default InvoiceUploadModal;
