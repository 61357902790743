import React, { useRef, useState, useEffect } from 'react'
import HeaderFirst from 'utils/HeaderFirst'
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';
import { usePermissions } from 'hooks/usePermissions';
import { useGetBalanceAgeeQuery } from 'actions/sage_clone/comptabilite/queries';
import { useParams } from 'react-router-dom';

import BarLoader from "react-spinners/BarLoader"

export default function BalanceAgee() {

  const {clientID} = useParams()
  const { data: balance_agee = {}, isLoading: isLoadingCode, refetch } = useGetBalanceAgeeQuery({
    id: 11,
    dossier: clientID
  })
  const [selectedTiers, setSelectedTiers] = useState('4');
  const permissions = usePermissions();
  // pour excel
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Balance_agee Générale table',
    sheet: 'Balance_agee'
  })

  // pour pdf
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

  const myRef = useRef(null);

  useEffect(function () {
    tableRef.current = myRef.current;
    targetRef.current = myRef.current;
  }, [myRef.current]);

  useEffect(() => {
    refetch()
  }, [isLoadingCode, balance_agee])


  var filteredBalanceAgee = []
  if (balance_agee &&
    Object.prototype.hasOwnProperty.call(balance_agee, 'balance_agee')){
      filteredBalanceAgee = balance_agee["balance_agee"].filter((ecriture) => (ecriture.numero_compte).startsWith(selectedTiers));
    }
  
  
  console.log("Filtered Balance Agée: ",filteredBalanceAgee)
  console.log("Balance Agée: ",balance_agee)



  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Balance Comptable: <span style={{ color: "#009688" }}>Agée</span>
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser l&apos;ensemble des écritures comptables de votre client.
          </div>
        }
      />

      {permissions.DOWNLOAD_ACCOUNTING_STATEMENTS && (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
              <div>
                <select id="fruit" value={selectedTiers} onChange={(e) => setSelectedTiers(e.target.value)}>
                  <option value="">Selectionner</option>
                  <option value="401">Fournisseurs</option>
                  <option value="411">Clients</option>
                </select>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <button onClick={onDownload}> Export excel </button>
                <button onClick={() => toPDF()}>Download PDF</button>
              </div>
            </div>
      )}


      <table id="customers" ref={myRef}>
        <tbody>
          <tr>
            <th>Numéro Comptable</th>
            <th>Intitulé</th>
            <th>Solde Compte</th>
            <th>Solde non échu</th>
            <th>0-30j</th>
            <th>31-45j</th>
            <th>46-60j</th>
            <th>+60j</th>
            <th>Total encours</th>
          </tr>
          {!isLoadingCode?(<>
            {filteredBalanceAgee.map((ecriture,index) => (
            <tr key={index}>
              <td>{ecriture.numero_compte}</td>
              <td>{ecriture.intitule}</td>
              <td>{ecriture.solde_compte}</td>
              <td>{ecriture.solde_non_echu}</td>
              <td>{ecriture.retard_1}</td>
              <td>{ecriture.retard_2}</td>
              <td>{ecriture.retard_3}</td>
              <td>{ecriture.retard_4}</td>
              <td>{ecriture.encours}</td>
            </tr>
          ))}
            <tr>
              <th>Total</th>
              <th>-</th>
              <th>-</th>
              <th>{filteredBalanceAgee.reduce(function(prev, cur) {
                    return prev + cur.solde_non_echu;
                  }, 0)}
              </th>
              <th>{filteredBalanceAgee.reduce(function(prev, cur) {
                    return prev + cur.retard_1;
                  }, 0)}
              </th>
              <th>{filteredBalanceAgee.reduce(function(prev, cur) {
                    return prev + cur.retard_2;
                  }, 0)}
              </th>
              <th>{filteredBalanceAgee.reduce(function(prev, cur) {
                    return prev + cur.retard_3;
                  }, 0)}
              </th>
              <th>{filteredBalanceAgee.reduce(function(prev, cur) {
                    return prev + cur.retard_4;
                  }, 0)}
              </th>
              <th>{filteredBalanceAgee.reduce(function(prev, cur) {
                    return prev + cur.encours;
                  }, 0)}
              </th>
            </tr>
          </>):(
              <tr className="global fournisseur-detail-container"
              style={{
                backgroundColor: 'white',
                minHeight: '700px',
                borderRadius: '20px',
                padding: '15px',
                opacity: '0.5',
                position: 'relative'
                
              }}>
                <td 
                  style={{
                    position: 'absolute',
                    top: '70%',
                    left: '50%',
                    colspan:"3"
                  }}
                >
                  <BarLoader />
                </td>
            </tr>
          )}

        </tbody>
      </table>

    </div>
  )
}
