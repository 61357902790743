import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderFirst from 'utils/HeaderFirst';
import InputDetail from 'components/Inputs/InputDetail';
import { toast } from 'react-toastify';
import ButtonP from 'components/buttons/ButtonP';
import { useUpdateCollaborateurMutation } from 'actions/collaborateur/mutation';
import { useGetCollaborateurIdQuery, useGetExpertDossiersQuery } from 'actions/collaborateur/queries';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

function CollaborateurDetails() {
  const { id: collaborateur } = useParams();
  const navigate = useNavigate();
  // Assuming you have a dossierOptions array with your dossiers
  const animatedComponents = makeAnimated();
  console.log(collaborateur);
  const currentUser = useGetCurrentUser();
  const {
    data: dossiersExpertResponse,
    refetch: refetchDossier
  } = useGetExpertDossiersQuery(currentUser.id);

  

  const {
    data: collaborateurData,
    isFetching,
    error,
    refetch
  } = useGetCollaborateurIdQuery({ id: collaborateur });
  const [updateCollaborateur, { isLoading }] = useUpdateCollaborateurMutation();
  const [selectedDossiers, setSelectedDossiers] = useState([]);

  const [collaborateurDetails, setCollaborateurDetails] = useState({
    first_name: '',
    last_name: '',
    adresse: '',
    email: '',
    phone: '',
    dossiers_assignes: []
  });

  const dossierOptions = dossiersExpertResponse.map((dossier) => ({
    value: dossier.id.toString(),
    label: dossier.nom_entreprise, // Adjust based on your dossier structure
  }));

  useEffect(() => {
    if (collaborateurData) {
      const details = collaborateurData.data[0];
      setCollaborateurDetails(details);
      refetch();
      refetchDossier();
      const mappedDossiers = details?.dossiers_assignes?.map((id) => ({
        value: id,
        label: dossierOptions.find(dossier => dossier.value.toString() === id)?.label || '',
      }));

      setSelectedDossiers(mappedDossiers);
    }
  }, [collaborateurData, setCollaborateurDetails]);

  const handleDossierChange = (selectedOptions) => {
    // Store only the IDs as an array of unique strings
    const selectedValues = selectedOptions
      ? [...new Set(selectedOptions.map(option => option.value.toString()))]
      : [];

    setSelectedDossiers(selectedOptions || []); // Store the selected options for display
    setCollaborateurDetails((prevDetails) => ({
      ...prevDetails,
      dossiers_assignes: selectedValues, // Update dossiers_assignes with unique string values
    }));
  };



  const saveChanges = async () => {
    console.log(collaborateurDetails);
    try {
      await updateCollaborateur({
        id: collaborateur,
        ...collaborateurDetails,
      }).unwrap();
      navigate(-1);
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour du collaborateur."
      );
    }
  };

  if (isFetching) return <div>Chargement...</div>;
  if (error) return <div>Une erreur s&#39;est produite.</div>;

  console.log(selectedDossiers)
  console.log(dossierOptions)

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Détails du collaborateur : {collaborateurDetails?.first_name} {" "} {collaborateurDetails?.last_name}
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser ou modifier les détails du collaborateur.
          </div>
        }
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveChanges();
        }}
        className="d-flex flex-column gap-2"
        style={{ padding: '15px' }}
      >
        <div className="row">
          <div className="col-md-6">
            <InputDetail
              label="Prénom"
              name="prenom"
              value={collaborateurDetails.first_name}
              onChange={(e) =>
                setCollaborateurDetails({
                  ...collaborateurDetails,
                  first_name: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Nom"
              name="nom"
              value={collaborateurDetails.last_name}
              onChange={(e) =>
                setCollaborateurDetails({
                  ...collaborateurDetails,
                  first_name: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <InputDetail
              label="Adresse"
              name="adresse"
              value={collaborateurDetails.adresse}
              onChange={(e) =>
                setCollaborateurDetails({
                  ...collaborateurDetails,
                  adresse: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Téléphone"
              name="telephone"
              value={collaborateurDetails.phone}
              onChange={(e) =>
                setCollaborateurDetails({
                  ...collaborateurDetails,
                  phone: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label
              className="form-label"
              htmlFor="form12"
              style={{ color: "#068F7A", fontWeight: 'bold' }}>
              Dossier(s) assigné(s)
            </label>
            <Select
              isMulti
              name="dossiers"
              closeMenuOnSelect={false}
              components={animatedComponents}
              options={dossierOptions}
              value={selectedDossiers}
              onChange={handleDossierChange}
              placeholder="Choisir un ou des dossiers"
              styles={{ borderRadius: "15px" }}
            />
          </div>
          <div className="col-md-6">
            <InputDetail
              label="Email"
              name="email"
              value={collaborateurDetails.email}
              disabled
              onChange={(e) =>
                setCollaborateurDetails({
                  ...collaborateurDetails,
                  first_name: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
        </div>
        <div className="my-3">
          <ButtonP type="submit" text={isLoading ? "Chargement..." : "Sauvegarder"} disabled={isLoading} />
        </div>
      </form>

    </div>
  );
}

export default CollaborateurDetails;
