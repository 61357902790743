// Modal.js
import React, {useEffect} from "react";
import "./Modal.css"; // Ensure this path matches your file structure
import {useFormContext} from "react-hook-form";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {getUser} from "redux/features/userSliceWithTokenValidation"; // Adjust according to your project structure
import {useCreateDossierMutation} from "actions/dossiers/mutations"; // Adjust according to your project structure
import GeneriqueModal from "./GeneriqueModal";
import ButtonCancel from "./buttons/ButtonCancel";
import ButtonGreen from "./buttons/ButtonGreen";

const Modal = ({isOpen, onClose, onDossierCreated}) => {
  const userStatus = useSelector(getUser);
  const [createDossier, {isLoading}] = useCreateDossierMutation();
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useFormContext();

  // Handle Enter key press to submit the form
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const onSubmit = (data) => {
    const dossierData = {...data, comptable: userStatus?.id};
    createDossier(dossierData)
      .unwrap()
      .then(() => {
        toast.success("Création du dossier réussi !");
        reset();
        onDossierCreated();
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "Erreur lors de la création du dossier.Remplissez tous les champs requis(*)"
        );
      });
  };

  return (
    <>
      <GeneriqueModal
        className="modal-overlay"
        onClose={onClose}
        title={"Ajouter un dossier"}
        isOpen={isOpen}>
        <form
          className="auth-form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}>
          {/* Identification */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div style={{width: "auto"}}>Identification</div>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "rgba(0,0,0,0.6)",
              }}></div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              marginLeft: "5%",
            }}>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="nom_entreprise"
                className="form-label"
                style={{width: "35%"}}>
                Raison sociale* :
              </label>
              <input
                className="form-control custom-rounded"
                id="nom_entreprise"
                placeholder="Nom de l'entreprise"
                {...register("nom_entreprise", {
                  required: "Nom de l’entreprise obligatoire",
                })}
                style={{width: "100%"}}
              />
              {errors.nom_entreprise && (
                <div className="text-danger">
                  {errors.nom_entreprise.message}
                </div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="forme_juridique"
                className="form-label"
                style={{width: "35%"}}>
                Forme Juridique* :
              </label>
              <input
                className="form-control custom-rounded"
                id="forme_juridique"
                placeholder="Forme Juridique"
                {...register("forme_juridique", {
                  required: "Forme juridique obligatoire",
                })}
                style={{width: "100%"}}
              />
              {errors.forme_juridique && (
                <div className="text-danger">
                  {errors.forme_juridique.message}
                </div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="secteur_activite"
                className="form-label"
                style={{width: "35%"}}>
                Secteur d&apos;activité :
              </label>
              <input
                className="form-control custom-rounded"
                id="secteur_activite"
                placeholder="Secteur"
                {...register("secteur_activite")}
                style={{width: "100%"}}
              />
              {errors.secteur_activite && (
                <div className="text-danger">
                  {errors.secteur_activite.message}
                </div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="capital_social"
                className="form-label"
                style={{width: "35%"}}>
                Capital social*:
              </label>
              <input
                className="form-control custom-rounded"
                id="capital_social"
                placeholder="Capital Social"
                {...register("capital_social", {
                  required: "Capital obligatoire",
                })}
                style={{width: "100%"}}
                type="number"
              />
              {errors.capital_social && (
                <div className="text-danger">
                  {errors.capital_social.message}
                </div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
                gridColumn: "2 / span 2",
              }}>
              <label
                htmlFor="description"
                className="form-label"
                style={{width: "auto", marginRight: "2px"}}>
                Description:
              </label>
              <input
                className="form-control custom-rounded"
                id="description"
                placeholder="Description"
                {...register("description")}
                style={{width: "100%"}}
              />
              {errors.description && (
                <div className="text-danger">{errors.description.message}</div>
              )}
            </div>
          </div>

          {/* Coordonnées */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div style={{width: "auto"}}>Cordonnées</div>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "rgba(0,0,0,0.6)",
              }}></div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              marginLeft: "5%",
            }}>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="adresse"
                className="form-label"
                style={{width: "35%"}}>
                Adresse* :
              </label>
              <input
                className="form-control custom-rounded"
                id="adresse"
                placeholder="Adresse"
                {...register("adresse", {required: "Adresse obligatoire"})}
                style={{width: "100%"}}
              />
              {errors.adresse && (
                <div className="text-danger">{errors.adresse.message}</div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="code_postal"
                className="form-label"
                style={{width: "35%"}}>
                Code Postal :
              </label>
              <input
                className="form-control custom-rounded"
                id="code_postal"
                placeholder="Code Postal"
                {...register("code_postal")}
                style={{width: "100%"}}
              />
              {errors.code_postal && (
                <div className="text-danger">{errors.code_postal.message}</div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="region"
                className="form-label"
                style={{width: "35%"}}>
                Région :
              </label>
              <input
                className="form-control custom-rounded"
                id="region"
                placeholder="Région"
                {...register("region")}
                style={{width: "100%"}}
              />
              {errors.region && (
                <div className="text-danger">{errors.region.message}</div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="ville"
                className="form-label"
                style={{width: "35%"}}>
                Ville :
              </label>
              <input
                className="form-control custom-rounded"
                id="ville"
                placeholder="Ville"
                {...register("ville")}
                style={{width: "100%"}}
              />
              {errors.ville && (
                <div className="text-danger">{errors.ville.message}</div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="nom_entreprise"
                className="form-label"
                style={{width: "35%"}}>
                Pays:
              </label>
              <input
                className="form-control custom-rounded"
                id="pays"
                placeholder="Pays"
                {...register("pays")}
                style={{width: "100%"}}
              />
              {errors.pays && (
                <div className="text-danger">{errors.pays.message}</div>
              )}
            </div>
          </div>

          {/* Télécommunications */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div style={{width: "auto"}}>Télécommunications</div>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "rgba(0,0,0,0.6)",
              }}></div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              marginLeft: "5%",
            }}>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="nom_entreprise"
                className="form-label"
                style={{width: "35%"}}>
                Numéro de Téléphone* :
              </label>
              <input
                className="form-control custom-rounded"
                id="telephone"
                placeholder="Téléphone"
                {...register("telephone", {
                  required:
                    "N° Tel doit être que des entiers et moins de 09 chiffres",
                  maxLength: 9,
                })}
                style={{width: "100%"}}
                type="number"
              />
              {errors.telephone && (
                <div className="text-danger">{errors.telephone.message}</div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="email"
                className="form-label"
                style={{width: "35%"}}>
                Adresse Email*:
              </label>
              <input
                className="form-control custom-rounded"
                id="email"
                placeholder="Email"
                {...register("email", {
                  required: "Email Obligatoire | Respecter le format mail",
                })}
                style={{width: "100%"}}
                type="email"
              />
              {errors.email && (
                <div className="text-danger">{errors.email.message}</div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="nom_entreprise"
                className="form-label"
                style={{width: "35%"}}>
                Site Internet :
              </label>
              <input
                className="form-control custom-rounded"
                id="site_internet"
                placeholder="Site internet"
                {...register("site_internet")}
                style={{width: "100%"}}
              />
              {errors.site_internet && (
                <div className="text-danger">
                  {errors.site_internet.message}
                </div>
              )}
            </div>
          </div>

          {/* Immatriculation */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <div style={{width: "auto"}}>Immatriculation</div>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "rgba(0,0,0,0.6)",
              }}></div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              marginLeft: "5%",
            }}>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="imma_siret"
                className="form-label"
                style={{width: "35%"}}>
                Siret :
              </label>
              <input
                className="form-control custom-rounded"
                id="imma_siret"
                placeholder="Siret"
                {...register("imma_siret")}
                style={{width: "100%"}}
              />
              {errors.imma_siret && (
                <div className="text-danger">{errors.imma_siret.message}</div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="imma_code_naf"
                className="form-label"
                style={{width: "35%"}}>
                Code NAF :
              </label>
              <input
                className="form-control custom-rounded"
                id="imma_code_naf"
                placeholder="Code NAF"
                {...register("imma_code_naf")}
                style={{width: "100%"}}
              />
              {errors.imma_code_naf && (
                <div className="text-danger">
                  {errors.imma_code_naf.message}
                </div>
              )}
            </div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 10px",
              }}>
              <label
                htmlFor="imma_idt_tva"
                className="form-label"
                style={{width: "35%"}}>
                Identifiant TVA :
              </label>
              <input
                className="form-control custom-rounded"
                id="imma_idt_tva"
                placeholder="Identifiant TVA"
                {...register("imma_idt_tva")}
                style={{width: "100%"}}
              />
              {errors.imma_idt_tva && (
                <div className="text-danger">{errors.imma_idt_tva.message}</div>
              )}
            </div>
          </div>

          {/* Modal Footer */}
          <div className="modal-footer">
            <ButtonCancel text={"Annuler"} onClick={onClose} />
            <ButtonGreen
              text={isLoading ? "Chargement..." : "Enregistrer"}
              type={"submit"}
              disabled={isLoading}
            />
          </div>
        </form>
      </GeneriqueModal>
    </>
  );
};

export default Modal;
