import { useUpdateUserMutation } from "actions/users/mutations";
import { useGetUsersByIdQuery } from "actions/users/queries";
import ButtonP from "components/buttons/ButtonP";
import InputDetail from "components/Inputs/InputDetail";
import useGetCurrentUser from "hooks/useGetCurrentUser";
import { usePermissions } from "hooks/usePermissions";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsImageFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUser } from "redux/features/userSliceWithTokenValidation";
import HeaderFirst from "utils/HeaderFirst";

const Paramètres = () => {
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      nom_entreprise: "",
      adresse: "",
      telephone: "",
      email: "",
      photoURL: null, // Added photoURL field
    },
  });

  const [logoUrl, setLogoUrl] = useState("");
  const user = useSelector(getUser);
  const currentUSer = useGetCurrentUser();
  console.log(currentUSer)
  const { data: userStatus, refetch } = useGetUsersByIdQuery(user?.id);
  const navigate = useNavigate();
  console.log(userStatus?.id)
  console.log(user);


  const [updateDossier] = useUpdateUserMutation();
  const permissions = usePermissions();

  useEffect(() => {
    if (userStatus) {
      reset({
        nom_entreprise: `${userStatus?.first_name} ${userStatus?.last_name}`,
        adresse: userStatus?.adresse,
        telephone: userStatus?.phone,
        email: userStatus?.email,
        photoURL: userStatus?.photo_url || "",
      });
      setLogoUrl(userStatus?.photo_url || "");
    }
    refetch();
  }, [userStatus, reset]);

  const nom = (
    <div style={{ display: "flex" }}>
      <h4 className="titre-dossier">Paramètres</h4>
    </div>
  );
  const description = (
    <div>Vous pouvez gérer ici les paramètres de votre compte</div>
  );

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoUrl(URL.createObjectURL(file));
      setValue("photoURL", file);
    }
  };

  const onSubmit = async (formData) => {
    const formDataObj = new FormData();
    formDataObj.append("first_name", formData.nom_entreprise.split(" ")[0]);
    formDataObj.append(
      "last_name",
      formData.nom_entreprise.split(" ")[1] || ""
    );
    formDataObj.append("adresse", formData.adresse);
    formDataObj.append("phone", formData.telephone);
    formDataObj.append("email", formData.email);
    formDataObj.append("isExpertComptable", userStatus.isExpertComptable);

    if (formData.photoURL) {
      formDataObj.append("photoURL", formData.photoURL);
    }

    try {
      const response = await updateDossier({
        id: userStatus.id,
        updateData: formDataObj,
      }).unwrap();

      toast.success("Profile updated successfully");
      reset({
        nom_entreprise: `${response.first_name} ${response.last_name}`,
        adresse: response.adresse,
        telephone: response.phone,
        email: response.email,
        description: response.description || "",
        photoURL: response.photo_url || "",
      });
      setLogoUrl(response.photo_url || "");
      refetch();
    } catch (error) {
      toast.error(error.message);
      console.error(error.message);
    }
  };

  const handleCancel = async () => {
    toast.info("Modifications annulées");
    navigate(-1);
  };

  console.log(logoUrl);

  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "500px",
        borderRadius: "20px",
        padding: "15px",
      }}>
      <div
        className="header pb-3"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}>
        <HeaderFirst
          nom={nom}
          description={description}
          enterprise={"Cabinet"}
        />
      </div>
      <div className="d-flex flex-column gap-2" style={{ padding: "15px" }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="form-layout d-flex flex-column gap-3 col-md-7"
          style={{ padding: "15px" }}>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="nom_entreprise"
                control={control}
                render={({ field }) => (
                  <InputDetail
                    label="Nom complet"
                    color="#000"
                    fontWeight={500}
                    fontSize={15}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="adresse"
                control={control}
                render={({ field }) => (
                  <InputDetail
                    label="Adresse"
                    color="#000"
                    fontWeight={500}
                    fontSize={15}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Controller
                name="telephone"
                control={control}
                render={({ field }) => (
                  <InputDetail
                    label="Numéro de téléphone"
                    color="#000"
                    fontWeight={500}
                    fontSize={15}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <InputDetail
                    label="E-mail"
                    color="#000"
                    fontWeight={500}
                    fontSize={15}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="galerie col-md-4 d-flex flex-column gap-1">
              <span
                style={{ color: "#000", fontWeight: 500, fontSize: "15px" }}
                className="form-label">
                Photo de profil
              </span>
              <div className="card cardImage text-center">
                {userStatus?.photo_url ? (
                  <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                    <img
                      src={`${logoUrl}`}
                      alt="Logo"
                      style={{ width: "100%" }}
                    />
                    <input
                      type="file"
                      id="imageInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleLogoChange}
                    />
                  </label>
                ) : (
                  <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                    <div
                      className="d-flex py-5 flex-column align-items-center justify-content-center"
                      style={{ color: "#BDBDBD" }}>
                      <BsImageFill className="fs-1 pt-3" />
                      <p className="m-0 p-0 pb-3">Ajouter une photo</p>
                    </div>
                    <input
                      type="file"
                      id="imageInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleLogoChange}
                    />
                  </label>
                )}
              </div>
            </div>
          </div>

          {permissions.MANAGE_FOLDER_SETTINGS && (
            <div className="form-navigation-buttons d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-danger me-2 rounded-pill"
                onClick={handleCancel}>
                Annuler
              </button>
              <ButtonP text="Mettre à jour le profil" type="submit" />
            </div>
          )}
        </form>

      </div>
    </div>
  );
};

export default Paramètres;
