import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./form.css";
import logo from "../../image/logo.png";
import { useFormContext } from "react-hook-form";
import { useRegisterUserMutation } from "actions/auth/mutations";
import iconeInscription from "../../image/iconeInscription.png";
import { LOGIN_PATH } from "routes/navigation/navigationPaths";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const InscriptionForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [logout] = useRegisterUserMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({
    email: "",
    phone: "",
    password: "",
  });

  const password = watch("password");
  const passwordConfirmation = watch("passwordConfirmation");

  const onSubmit = (data) => {
    if (password !== passwordConfirmation) {
      setError({ ...error, password: "Les mots de passe ne correspondent pas" });
      return;
    }

    setLoading(true);
    console.log("Formulaire soumi avec les données :", data);

    const formData = new FormData();
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("phone", data.phone);
    formData.append("adresse", data.adresse);
    formData.append("email", data.email);
    formData.append("password", data.password);

    logout(formData)
      .unwrap()
      .then((res) => {
        toast.success(
          "Nous avons envoyé le lien dans votre boîte de réception. Activez votre compte à partir de votre compte de messagerie mail"
        );
        console.log(res);
        navigate(LOGIN_PATH);
      })
      .catch((error) => {
        console.log(error);
        toast.error({ message: error.message });
        setError(error.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row  auth-wrapper">
          <div className="col-12 col-md-6 col-lg-6 auth-main-col text-center ">
            <div className="d-flex flex-column align-items-end h-100 side">
              <div className="auth-body mx-auto" style={{ width: "450px" }}>
                <div className="logo-app">
                  <img src={logo} />
                </div>
                <div className="">
                  <h6 className="title-connecter">Créer un nouveau compte</h6>
                  <p className="paragraphe">
                    Ce n&apos;est pas difficile, il suffit de saisir quelques
                    données et c&apos;est fait tout de suite !
                  </p>
                </div>

                <div className="auth-form-container text-start ">
                  <form
                    className="auth-form"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off">
                    <div className="extra mt-3 row justify-content-between mb-3">
                      <label
                        htmlFor="text"
                        className="form-label "
                        style={{ fontSize: "15px" }}>
                        Prénom et Nom
                      </label>
                      <div className="col-6">
                        <input
                          className="form-control custom-rounded"
                          type="text"
                          placeholder="Prenom"
                          {...register("first_name")}
                          // checked={remember}
                          // onChange={(e) => setRemember(e.currentTarget.checked)}

                          required
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className="form-control custom-rounded"
                          type="text"
                          placeholder="Nom"
                          {...register("last_name")}
                          // checked={remember}
                          // onChange={(e) => setRemember(e.currentTarget.checked)}
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <label
                        htmlFor="phone"
                        className="form-label"
                        style={{ fontSize: "15px" }}>
                        Téléphone
                      </label>
                      <input
                        type="tel"
                        className="form-control custom-rounded"
                        id="phone"
                        placeholder="770000000"
                        {...register("phone", {
                          required: "Telephone  est obligatoire",
                        })}
                        required
                      />
                      {error.phone != "" && (
                        <div className="text-danger">{error.phone}</div>
                      )}
                    </div>
                    <div className="mb-2">
                      <label
                        htmlFor="adresse"
                        className="form-label"
                        style={{ fontSize: "15px" }}>
                        Adresse
                      </label>
                      <input
                        type="text"
                        className="form-control custom-rounded"
                        id="adresse"
                        placeholder="adresse"
                        {...register("adresse")}
                      />
                      {errors.adresse?.message && (
                        <div className="text-danger">
                          {errors.adresse.message}
                        </div>
                      )}
                    </div>
                    <div className="mb-2">
                      <label
                        htmlFor="email"
                        className="form-label"
                        style={{ fontSize: "15px" }}>
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control custom-rounded"
                        id="email"
                        placeholder="Information@gmail.com"
                        {...register("email", {
                          required: "Email  est obligatoire",
                        })}
                      />
                      {error.email != "" && (
                        <div className="text-danger">{error.email}</div>
                      )}
                    </div>

                    <div className="mb-2">
                      <label
                        htmlFor="password"
                        className="form-label"
                        style={{ fontSize: "15px" }}>
                        Mot de passe
                      </label>
                      <div className="d-flex align-items-center justify-content-end">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control custom-rounded"
                          id="password"
                          placeholder="Entrer votre mot de passe"
                          {...register("password", {
                            required: "Password is required",
                          })}
                          required
                        />
                        <span
                          className="password-toggle-icon me-3"
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            display: "flex",
                          }}>
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                      </div>
                      {error.password && (
                        <div className="text-danger">{error.password}</div>
                      )}
                    </div>
                    <div className="mb-2">
                      <label
                        htmlFor="passwordConfirmation"
                        className="form-label"
                        style={{ fontSize: "15px" }}>
                        Confirmation de mot de passe
                      </label>
                      <div className="d-flex align-items-center justify-content-end">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control custom-rounded"
                          id="passwordConfirmation"
                          placeholder="Confirmer votre mot de passe"
                          {...register("passwordConfirmation", {
                            required: "Password confirmation is required",
                          })}
                          required
                        />
                        <span
                          className="password-toggle-icon me-3"
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            display: "flex",
                          }}>
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                      </div>
                      {error.password && (
                        <div className="text-danger">{error.password}</div>
                      )}
                    </div>
                    <div className="text-inscription">
                      <p>
                        En vous inscrivant, vous acceptez les conditions
                        d&apos;utilisation et la politique de confidentialité de
                        Bakeli
                      </p>
                    </div>

                    <div className="text-center">
                      {loading ? (
                        <CircularProgress color="primary" size={24} /> // Render loader when loading is true
                      ) : (
                        <button type="submit" className="button-btn  w-100 ">
                          S&apos;enregistrer
                        </button>
                      )}
                    </div>
                  </form>
                  <div className="auth-option  linker">
                    Avez-vous un compte?
                    <Link className="text-link" to={"/"}>
                      &nbsp; connectez-vous ?
                    </Link>
                  </div>
                  {/* <hr /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6  auth-background-col">
            <div className="auth-background-holder">
              {/* <img src={iconeInscription} className="img"/> */}
              <div className="allIconImage">
                <img src={iconeInscription} className="img" />
              </div>
              <div className="text-advic ">
                <h6>Easy compta</h6>
                <p className="advice">
                  Allez, inscrivez-vous et plonger dans le monde passionnant de
                  la technologie financière. Conçue pour répondre à vos besoins
                  comptables les plus complexes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InscriptionForm;
