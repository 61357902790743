// Modal.js
import React, { useState } from 'react';
import './Modal.css';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { useAddFileClientsMutation } from 'actions/importer_fichier/mutations';

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import FileUpload from './File/FileUpload';
import GeneriqueModal from 'components/GeneriqueModal';
import ButtonCancel from 'components/buttons/ButtonCancel';
import ButtonGreen from 'components/buttons/ButtonGreen';


const ModalExcel = ({ isOpen, onClose, refetch }) => {
  const { clientID: id } = useParams();
  // fichier joint
  const [selectedFile, setSelectedFile] = useState(null);
  const [selected, setSelected] = useState(false)

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    setSelected(true)
  };

  const [addFile, { isLoading }] = useAddFileClientsMutation()
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for the presence of a file to be uploaded.
    if (selectedFile) {
      const formData = new FormData();

      formData.append('file', selectedFile);
      formData.append('dossier_id', id);
      // Perform the POST request
      try {
        await addFile({ dossier: id, formData: formData }).unwrap();
        toast.success('Fichier ajouté avec succès');
        refetch()
        onClose()
      } catch (error) {
        console.error('Requête échouée:', error);
        toast.error(`Erreur: ${error.data?.message}` || "Echec d'ajout de dépense");
      }
    }
  };


  return (
    <>
      <GeneriqueModal className="modal-overlay" onClose={onClose} isOpen={isOpen} title="IMPORTER VOS FICHIERS CLIENTS">
        <Container fluid className="container-style p-4">
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="d-flex flex-column align-items-center gap-5">
              <Row>
                <Form.Group controlId="fileUploadControl" className="w-100">
                  <FileUpload onFileSelect={handleFileSelect} />
                </Form.Group>
              </Row>
              <Row className="mt-2">
                <Col sm={12} className="text-center">
                  <div className="d-flex gap-3">
                    <ButtonCancel text={"Annuler"} onClick={() => onClose()} />
                    <ButtonGreen text={isLoading ? "Chargement..." : "Enregistrer"} type={"submit"} disabled={
                      !selected || isLoading && selected
                    } />
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </Container>
      </GeneriqueModal>
    </>
  );
};

export default ModalExcel;
