
import React, { useRef, useState } from 'react'
import HeaderFirst from 'utils/HeaderFirst'
import { useParams } from 'react-router-dom';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';
import "../DisplayJournal/style.css"
import { useEffect } from "react";
import { useGetBalanceAuxiliaireQuery } from 'actions/sage_clone/comptabilite/queries';
import { usePermissions } from 'hooks/usePermissions';

import BarLoader from "react-spinners/BarLoader"

export default function Balance_auxiliaireAuxiliaire() {
  const { clientID } = useParams();
  const permissions = usePermissions();
  const { data: balance_auxiliaire = {}, isLoading: isLoadingCode, refetch } = useGetBalanceAuxiliaireQuery({
    id: 11,
    dossier: clientID
  })

  const [selectedTiers, setSelectedTiers] = useState('');
  // pour excel
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Balance_auxiliaire Générale table',
    sheet: 'Balance_auxiliaire'
  })

  // pour pdf
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

  const myRef = useRef(null);


  useEffect(function () {
    tableRef.current = myRef.current;
    targetRef.current = myRef.current;
  }, [myRef.current]);

  useEffect(() => {
    refetch()
  }, [isLoadingCode, balance_auxiliaire])

  var filteredBalanceAuxiliaire = []
  if (balance_auxiliaire &&
    Object.prototype.hasOwnProperty.call(balance_auxiliaire, 'balance_auxiliaire')) {
    filteredBalanceAuxiliaire = balance_auxiliaire["balance_auxiliaire"]?.filter((ecriture) => {
      var ecr_string = "" + (ecriture.numero_comptable).toString()
      if (selectedTiers != "") {
        return ecr_string.includes(selectedTiers)
      }
      else
        return ecriture
    });
  }
  console.log(filteredBalanceAuxiliaire)

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Balance Comptable: <span style={{ color: "#009688" }}>Auxiliaire</span>
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser l&apos;ensemble des écritures comptables de votre client.
          </div>
        }
      />
      {permissions.DOWNLOAD_ACCOUNTING_STATEMENTS && (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
          <div>
            <select id="fruit" value={selectedTiers} onChange={(e) => setSelectedTiers(e.target.value)}>
              <option value="">Selectionner</option>
              <option value="401">Fournisseurs</option>
              <option value="411">Clients</option>
            </select>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <button onClick={onDownload}> Export excel </button>
            <button onClick={() => toPDF()}>Download PDF</button>
          </div>
        </div>
      )}

      <table id="customers" ref={myRef}>
        <tbody>
          <tr>
            <th>Compte Comptable</th>
            <th>Libelle</th>
            <th>Date</th>
            <th>Débit</th>
            <th>Crédit</th>
            <th>Solde</th>
          </tr>
          {!isLoadingCode?(<>
            {balance_auxiliaire &&
            Object.prototype.hasOwnProperty.call(balance_auxiliaire, 'balance_auxiliaire') &&
            filteredBalanceAuxiliaire?.map((bloc) => {
              return (
                bloc["elements"].map((ecriture, index) => {
                  return (
                    <tr key={index}>
                      <td>{ecriture.compte_tiers}</td>
                      <td>{ecriture.libelle}</td>
                      <td>{ecriture.date}</td>
                      <td>{ecriture.debit}</td>
                      <td>{ecriture.credit}</td>
                      <td>{ecriture.debit - ecriture.credit}</td>
                    </tr>
                  )
                })
              )
            })
          }
          {/*<tr>
            <th>-</th>
            <th>-</th>
            <th>Total</th>
            <th>{filteredBalanceAuxiliaire &&
                 Object.prototype.hasOwnProperty.call(balance_auxiliaire,'total_debits') &&
                 selectedTiers==""?balance_auxiliaire["total_debits"]:filteredBalanceAuxiliaire[0]["debit"]}
            </th>
            <th>{filteredBalanceAuxiliaire &&
                 Object.prototype.hasOwnProperty.call(balance_auxiliaire,'total_credits') &&
                 selectedTiers==""?balance_auxiliaire["total_credits"]:filteredBalanceAuxiliaire[0]["debit"]}
            </th>
            <th>{filteredBalanceAuxiliaire &&
                 Object.prototype.hasOwnProperty.call(balance_auxiliaire,'total_debits') &&
                 selectedTiers==""?(balance_auxiliaire["total_debits"]-balance_auxiliaire["total_credits"]):
                 (filteredBalanceAuxiliaire[0]["debit"]-filteredBalanceAuxiliaire[0]["credit"])}
            </th>
          </tr>*/}
          </>):(
            <tr className="global fournisseur-detail-container"
              style={{
                backgroundColor: 'white',
                minHeight: '700px',
                borderRadius: '20px',
                padding: '15px',
                opacity: '0.5',
                position: 'relative'
                
              }}>
                <td 
                  style={{
                    position: 'absolute',
                    top: '70%',
                    left: '50%',
                    colspan:"3"
                  }}
                >
                  <BarLoader />
                </td>
            </tr>
          )}
          

        </tbody>

      </table>

    </div>
  )
}
