import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import HeaderFirst from "utils/HeaderFirst";
import HeaderButton from "utils/headerButton";
import CustomBootstrapTable from "utils/reactTable";
import ModalClient from "./Modal";
import arrow from "../../../image/arrow-left.png";
import { useNavigate } from "react-router-dom";
import "./client.css";
import TablePaginators from "components/Pagination/TablePaginator";
//import useClientData from './useCollaborateurData';
import { toast } from "react-toastify";
import useCollaborateurData from "./useCollaborateurData";
import useGetCurrentUser from "hooks/useGetCurrentUser";
import { useGetExpertDossiersQuery } from "actions/collaborateur/queries";
import { usePermissions } from "hooks/usePermissions";
import ButtonWhite from "components/buttons/ButtonWhite";
// import axios from 'axios';
// import { useGetClientQuery } from 'actions/dossiers/queries';

const CollaborateurPage = () => {
  //const { clientID: dossier_id } = useParams();
  const [serachTerm, setSearchedTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const permissions = usePermissions();
  const {
    updatedCollaborateurs,
    handleUpdateCollaborateur,
    handleDeleteCollaborateur,
    refetch: refetchCollaborateur,
  } = useCollaborateurData();
  const [viewArchived, setViewArchived] = useState(false);

  const currentUser = useGetCurrentUser();
  const {
    data: dossiersExpertResponse,
    isLoading: isLoadingExpertDossiers,
    refetch,
  } = useGetExpertDossiersQuery(currentUser.id);

  console.log("The current user id: ", currentUser.id);
  console.log(
    "The current user dossiers: ",
    dossiersExpertResponse?.encadreur_expert_comptable === currentUser.id
  );

  useEffect(() => {
    refetchCollaborateur();
  }, [refetchCollaborateur]);

  const filteredData = updatedCollaborateurs
    .filter((collaborateur) =>
      viewArchived ? collaborateur.archived : !collaborateur.archived
    )
    .filter((collaborateur) =>
      collaborateur?.first_name
        ?.toLowerCase()
        .includes(serachTerm.toLowerCase())
    );

  console.log("Mes collaborateurs: ", updatedCollaborateurs);
  console.log(
    "Mes collaborateurs: ",
    updatedCollaborateurs.map((collaborateur) => collaborateur.id)
  );

  const navigate = useNavigate();

  const currentPageData = filteredData.slice(
    paginationIndex * pageSize,
    (paginationIndex + 1) * pageSize
  );

  const handleActionSelected = (action) => {
    if (action === "archive") {
      setViewArchived(!viewArchived); // Toggle between viewing archived and active depenses
    } else {
      console.log(`Action selected: ${action}`);
      // Handle other actions as necessary
    }
  };

  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const handleSearchChange = (newSearchTerm) => {
    setSearchedTerm(newSearchTerm);
  };

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row.id]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const rowEvents = {
    onClick: (e, row) => {
      console.log("Table row clicked:", row); // Check if this logs
      handleRowClick(row);
    },
  };

  const columns = [
    {
      dataField: "first_name",
      text: "Prénom",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "last_name",
      text: "Nom",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "created_at",
      text: "Date d'ajout",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "phone",
      text: "N° de téléphone",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "email",
      text: "E-mail",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "fonction",
      text: "Rôle",
      formatter: (cell) => <div>{cell.title}</div>,
    },
    {
      dataField: "is_active",
      text: "Statut",
      formatter: (cell) => (cell ? "Actif" : "Inactif"),
    },
  ];

  const handleDossierCreated = () => {
    setIsModalOpen(false);
    refetchCollaborateur();
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
    refetchCollaborateur();
  };

  const openModal = () => {
    setIsModalOpen(true);
    refetchCollaborateur();
    refetch();
  };

  const nom = (
    <div style={{ display: "flex" }}>
      <h4 className="titre-dossier">{viewArchived ? "Collaborateurs archivés" : "Collaborateurs"}</h4>
      <span className="nbrDossier">{filteredData?.length}</span>
    </div>
  );
  const description = <div>Voici tous vos collaborateurs</div>;

  const handleRowClick = (row) => {
    const clickedRowId = row?.id;
    console.log(clickedRowId);
  };

  const onEyesClick = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const detailClientURL = `details/${selectedRowId}/`;
      navigate(detailClientURL);
      console.log(selectedRowId);
    }
  };

  const onClickDelete = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const collaborateur = updatedCollaborateurs.find(
        (d) => d.id === selectedRowId
      );
      if (!collaborateur) {
        toast.error("Collaborateur non trouvé.");
        return;
      }

      try {
        await handleDeleteCollaborateur(selectedRowId);
        setSelectedRows([]);
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour du collaborateur :",
          error
        );
      }
    } else {
      toast.warning(
        "Veuillez sélectionner exactement un collaborateur à supprimer."
      );
    }
  };

  const onClickArchive = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const collaborateur = updatedCollaborateurs.find(
        (d) => d.id === selectedRowId
      );
      if (!collaborateur) {
        toast.error("Collaborateur non trouvé.");
        return;
      }

      try {
        const newArchivedStatus = !collaborateur.active;
        await handleUpdateCollaborateur(selectedRowId, {
          active: newArchivedStatus,
          archived: true,
        });
        toast.info(
          `Collaborateur ${newArchivedStatus ? "archivé" : "désarchivé"
          } avec succès.`
        );
        setSelectedRows([]);
      } catch (error) {
        toast.error(
          "Une erreur est survenue lors de l'archivage du collaborateur."
        );
        console.error(
          "Erreur lors de la mise à jour du collaborateur :",
          error
        );
      }
    } else {
      toast.warning(
        "Veuillez sélectionner exactement un client à archiver ou désarchiver."
      );
    }
  };

  const onActivedClick = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const collaborateur = updatedCollaborateurs.find(
        (d) => d.id === selectedRowId
      );
      if (!collaborateur) {
        toast.error("Collaborateur non trouvé.");
        return;
      }

      try {
        const newActiveStatus = !collaborateur.active;
        await handleUpdateCollaborateur(selectedRowId, {
          active: newActiveStatus,
        });
        toast.info(
          `Collaborateur ${newActiveStatus ? "activé" : "désactivé"
          } avec succès.`
        );
      } catch (error) {
        toast.error(
          "Une erreur est survenue lors de la mise à jour du collaborateur."
        );
        console.error(
          "Erreur lors de la mise à jour du collaborateur :",
          error
        );
      }
    } else {
      toast.warning(
        "Veuillez sélectionner exactement un collaborateur à activer ou désactiver."
      );
    }
  };

  const methods = useForm();
  return (
    <div
      className="global"
      style={{
        backgroundColor: "white",
        minHeight: "500px",
        borderRadius: "20px",
        padding: "15px",
        overflow: "scroll",
      }}>
      <div
        className="header pb-3"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          // padding: "13px",
        }}>
        <HeaderFirst
          nom={nom}
          description={description}
          enterprise={"Cabinet"}
        />
        {permissions.MANAGE_COLLABORATORS && (
          <ButtonWhite onClick={openModal} text={"+ Ajouter un collaborateur"} />
        )}

        {isModalOpen && (
          <FormProvider {...methods}>
            <ModalClient
              isOpen={isModalOpen}
              onClose={closeModal}
              onDossierCreated={handleDossierCreated}
              clients={updatedCollaborateurs}
              dossiersExpertResponse={dossiersExpertResponse}
              refetch={refetch}
              isLoadingExpertDossiers={isLoadingExpertDossiers}>
              <h2
                style={{ color: " #009688", fontSize: "20px", fontWeight: 700 }}>
                <span>
                  {" "}
                  <img src={arrow} />
                </span>{" "}
                &nbsp;CREER UN NOUVEAU DOSSIER COLLABORATEUR
              </h2>
            </ModalClient>
          </FormProvider>
        )}
      </div>
      {permissions.MANAGE_COLLABORATORS && (
        <HeaderButton
          placeholder={"Rechercher un collaborateur"}
          onSearchChange={handleSearchChange}
          isButtonsEnabled={selectedRows.length > 0}
          onClickArchive={onClickArchive}
          onActivedClick={onActivedClick}
          onEyesClick={onEyesClick}
          onClickDelete={onClickDelete}
        />
      )}

      <div>
        {updatedCollaborateurs ? (
          <CustomBootstrapTable
            keyField="id"
            data={currentPageData?.filter((collaborateur) =>
              collaborateur?.first_name
                .toLowerCase()
                .includes(serachTerm.toLowerCase())
            )}
            columns={columns}
            selectedRows={selectedRows}
            onSelectRow={handleRowSelect}
            wrapperClasses="table-wrapper"
            rowEvents={rowEvents}
          />
        ) : (
          <p>Chargement des données...</p>
        )}
      </div>
      {currentPageData && currentPageData.length > 0 && (
        <TablePaginators
          paginationIndex={paginationIndex}
          setPaginationIndex={handlePageChange}
          dataLength={filteredData.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onActionSelected={handleActionSelected}
          isViewingArchived={viewArchived}
        />
      )}
    </div>
  );
};
export default CollaborateurPage;
