import React from "react";
import "./button.css";

export default function ButtonCancel({
    type,
    text,
    disabled,
    onClick,
    className,
}) {
    return (
        <div>
            <button
                type={type}
                className={`cancel-button ${className} rounded-pill`}
                disabled={disabled}
                onClick={onClick}>
                {text}
            </button>
        </div>
    );
}
