import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "normalize.css"
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "redux/store";
import Loader from "components/Loader/Loader";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AppProvider } from "layouts/appConexte";
import CacheBuster from "CashBuster";
import logo from './image/logo.png';

const BustedCachLoader = () => {
  return <div className="h-100 d-flex flex-column justify-content-center align-items-center">
    <img src={logo} />
    <p style={{ maxWidth: "700px", fontSize: "18px", textAlign: "center", marginTop: 10 }}>
      Easy Compta simplifie la gestion financière, vous permettant
      de suivre vos revenus et dépenses, générer des rapports
      détaillés et rester toujours en contrôle de votre
      comptabilité.
    </p>
    <p>Version {global.appVersion}</p>
  </div>
}

if (process.env.NODE_ENV === "production") {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return <BustedCachLoader />;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <Provider store={store}>
            <PersistGate loading={<Loader />} persistor={persistor}>
              <AppProvider>
                <App />
              </AppProvider>
              <ToastContainer />
            </PersistGate>
          </Provider>
        );
      }}
    </CacheBuster>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
