import { useEffect, useState } from 'react';
//import { toast } from 'react-toastify';
import {  useGetJournalByDossierClientIdQuery} from 'actions/sage_clone/journaux/queries';
//import { useDeleteLivreJournalMutation,useUpdateLivreJournalMutation } from 'actions/sage_clone/journaux/mutations';

const useJournalData = (dossier_id) => {
  const {
    data: journalResponse,
    isLoading: isLoadingjournal,
    refetch,
  } = useGetJournalByDossierClientIdQuery(dossier_id)
  console.log(dossier_id)

  //const [updateJournal] = useUpdateLivreJournalMutation();
  //const [deleteJournal] = useDeleteLivreJournalMutation();

  const [updatedJournal, setUpdatedJournal] = useState([]);

  useEffect(() => {
    if (
      !isLoadingjournal &&
      journalResponse?.data &&
      Array.isArray(journalResponse.data)
    ) {
      const enhancedjournal = journalResponse.data.map((journal) => ({
        ...journal,
        displayName: `${journal.name} (${journal.id})`,
      }));
      setUpdatedJournal(enhancedjournal);
    }
    refetch()
  }, [isLoadingjournal, journalResponse]);

  /*const handleUpdateJournal = async (journalId, updateData) => {
    try {
      await updateJournal({
        id: journalId,
        ...updateData,
      }).unwrap();
      refetch(); // Refetch client data from the backend
    } catch (error) {
      toast.error(`Erreur de mise à jour: ${error.data?.error || error.message}`);
      console.error('Requête échouée:', error);
    }
  };

  const handleDeleteJournal = async (journalId) => {
    try {
      await deleteJournal({ id: journalId }).unwrap();
      toast.success('journal deleted successfully');
      refetch();
    } catch (error) {
      console.log(journalId)
      toast.error(`Deletion error: ${error.data?.error || error.message}`);
    }
  };*/

  return { updatedJournal,  refetch };
};

export default useJournalData;
