import useGetAuthenticate from "hooks/useIsAuthenticatedUser";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "routes/navigation/navigationPaths";
import styled from "styled-components";

const NotFoundPane = styled.h1`
  text-align: center;
`;

const PageNotFound = () => {
  const navigate = useNavigate();
  const isAuthenticated = useGetAuthenticate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(LOGIN_PATH)
    }
  }, [isAuthenticated])
  return <NotFoundPane>
    <span>Page Inexistante</span>
    <p>404</p>
  </NotFoundPane>;
};

export default PageNotFound;
