import React from "react";
import PropTypes from "prop-types";

const InitialsAvatar = ({ firstName, lastName, photoURL, style, alt }) => {
    const getInitials = (firstName, lastName) => {
        const firstNameInitial = firstName ? firstName[0].toUpperCase() : "";
        const lastNameInitial = lastName ? lastName[0].toUpperCase() : "";
        return `${firstNameInitial}${lastNameInitial}`;
    };

    const profileStyle = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "50%",
        backgroundColor: "#f0f0f0",
        border: "1px solid #bbb",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#068f7a",
    };

    return photoURL && photoURL !== null ? (
        <img
            src={photoURL}
            alt={alt}
            style={{ width: "100%", height: "100%", borderRadius: "50%", ...style }}
        />
    ) : (
        <div style={{ ...profileStyle }}>
            {getInitials(firstName, lastName)}
        </div>
    );
};

InitialsAvatar.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    photoURL: PropTypes.string,
    style: PropTypes.object,
    alt: PropTypes.string,
};

export default InitialsAvatar;
