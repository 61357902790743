import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
//import logo from '../../image/logo.png';
import { getUser, logout } from 'redux/features/userSliceWithTokenValidation';
import { useDispatch, useSelector } from 'react-redux';
import Logout from '../../image/log-out.png';
import Bell from '../../image/bell.png';
import Param from '../../image/Group 101987.png';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PATH, PARAMETRE_PATH } from 'routes/navigation/navigationPaths';
import { NavDropdown } from 'react-bootstrap';
import InitialsAvatar from 'components/InitialsAvatar';
import { useGetUsersByIdQuery } from 'actions/users/queries';

function NavbarComponentCabinet({ bgColor, navbarContainerStyles }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleLogout = async () => {
    try {
      await dispatch(logout());
      console.log('Déconnexion');
      navigate(LOGIN_PATH);
      // Redirect or perform any additional actions after successful logout
    } catch (error) {
      console.error('Déconnexion échouée:', error);
      // Handle error if the logout fails
    }
  };

  const handleParamClick = () => {
    navigate(PARAMETRE_PATH);
  };
  const userStatus = useSelector(getUser);
  const { data: user, refetch } = useGetUsersByIdQuery(userStatus?.id);

  useEffect(() => {
    if (user) {
      refetch()
    }
  }, [user]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        height: 60,
        backgroundColor: bgColor ? bgColor : '#F6F8F9',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        ...navbarContainerStyles,
      }}
    >

      <div style={{ flex: 1 }}>
        <div className="searchBar d-none d-lg-block">
          <input
            style={{
              width: '100%',
              maxWidth: '600px',
              borderRadius: '15px',
              boxSizing: 'border-box',
            }}
            type="text"
            placeholder="Recherche dans Easy compta"
            className="form-control"
          />
        </div>
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}
      >
        <div
          style={{
            width: "35px",
            height: "35px",
          }} className='me-2'>
          <InitialsAvatar
            firstName={userStatus?.first_name}
            lastName={userStatus?.last_name}
            photoURL={user?.photo_url}
            alt="user"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <p style={{ margin: 0 }} className="me-3 me-lg-0 d0">
          {/* Mouhamed */}
          {userStatus?.first_name} {userStatus?.last_name}
        </p>
        <img
          onClick={handleParamClick}
          src={Param}
          className="imgs mx-2 d-none d-lg-block"
          style={{ Width: '18px', height: '18px', cursor: 'pointer' }}
        />
        <img
          onClick={handleLogout}
          src={Logout}
          className="imgs mx-2 d-none d-lg-block"
          style={{ Width: '18px', height: '18px', cursor: 'pointer' }}
        />
        <NavDropdown
          title=""
          id="basic-nav-dropdown"
          className="d-lg-none d-block"
        >
          <NavDropdown.Item>
            <div className="searchBar">
              <input
                style={{
                  width: 'max-width',
                  maxWidth: '600px',
                  borderRadius: '15px',
                  boxSizing: 'border-box',
                }}
                type="text"
                placeholder="Recherche dans Easy compta"
                className="form-control"
              />
            </div>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <div className="d-flex align-items-start">
              <img
                onClick={handleLogout}
                src={Bell}
                className="imgs mx-2"
                style={{ Width: '18px', height: '18px' }}
              />
              <h4 className='fs-6'>Notifications</h4>
            </div>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <div className="d-flex align-items-start">
              <img
                onClick={handleParamClick}
                src={Param}
                className="imgs mx-2"
                style={{ Width: '18px', height: '18px' }}
              />
              <h4 className='fs-6'>Paramètres</h4>
            </div>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item>
            <div className="d-flex align-items-start">
              <img
                onClick={handleLogout}
                src={Logout}
                className="imgs mx-2"
                style={{ Width: '18px', height: '18px' }}
              />
              <h4 className='fs-6'>Déconnexion</h4>
            </div>
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    </div>
  );
}

// Add PropTypes validation for the props
NavbarComponentCabinet.propTypes = {
  // bgColor: PropTypes.string,
  navbarContainerStyles: PropTypes.object,
};

export default NavbarComponentCabinet;
