import React from "react";

export default function InputDetail({
  value,
  disabled,
  label,
  type = "text",
  onChange,
  className,
  isTextarea = false,
  color = "#068F7A",
  fontWeight = "bold",
  fontSize,
  maxLength
}) {
  return (
    <div>
      <div className="form-outline" data-mdb-input-init>
        <label
          className="form-label"
          htmlFor="form12"
          style={{ color: color, fontWeight: fontWeight, fontSize: fontSize }}>
          {label}
        </label>
        {isTextarea ? (
          <textarea
            value={value}
            id="form12"
            className={`form-control fs-6 ${className}`}
            disabled={disabled}
            onChange={onChange}
            rows={5}
          />
        ) : (
          <input
            type={type}
            value={value}
            id="form12"
            className={`form-control fs-6 rounded-pill ${className}`}
            disabled={disabled}
            onChange={onChange}
            maxLength={maxLength}
          />
        )}
      </div>
    </div>
  );
}
