import React, {useEffect, useState} from "react";
import {Form, Container, Row, Col} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
//import 'components/AddDepenses/style.css';
import {useCreateCollaborateurMutation} from "actions/collaborateur/mutation";
import DossierCheckBox from "./DossierCheck/DossierCheckBox";
import useGetCurrentUser from "hooks/useGetCurrentUser";
import {useGetRolesQuery} from "actions/roles/queries";
import GeneriqueModal from "components/GeneriqueModal";
//import useGetCurrentUser from 'hooks/useGetCurrentUser';
//import { useGetExpertDossiersQuery } from 'actions/collaborateur/queries';

export default function ModalClient({
  isOpen,
  onClose,
  onDossierCreated,
  clients,
  isLoadingExpertDossiers,
  dossiersExpertResponse,
  refetch,
}) {
  console.log(clients);
  const navigate = useNavigate();
  const currentUser = useGetCurrentUser();

  console.log("Mes dossiers: ", dossiersExpertResponse);
  console.log("Chargement: ", isLoadingExpertDossiers);
  const [createCollaborateur, {isLoading}] = useCreateCollaborateurMutation();
  const {
    data: roleResponse,
    isLoading: isLoadingRole,
    error,
  } = useGetRolesQuery();
  const [roles, setRoles] = useState([]);
  // Function to generate a 6-character random password
  const generatePassword = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < 6; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };

  const [newCollaborateur, setNewCollaborateur] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    adresse: "",
    email: "",
    password: generatePassword(), // Set generated password
    is_active: true,
    encadreur_expert_comptable: currentUser?.id,
    dossiers_assignes: [],
    fonction: "",
    is_superuser: false,
  });

  useEffect(() => {
    if (!isLoadingRole) {
      if (error) {
        console.error("Error fetching roles:", error);
      } else {
        refetch();
        setRoles(roleResponse);
      }
    }
  }, [isLoadingRole, roleResponse, error]);

  // Handle input changes
  const handleInputChange = (event) => {
    const {name, value, type, checked} = event.target;

    setNewCollaborateur((prevState) => {
      if (type === "checkbox") {
        return {...prevState, [name]: checked};
      } else {
        return {...prevState, [name]: value};
      }
    });
  };

  // Handle select change for dossiers checked
  const handleDossiersChecked = (index, event, dossier) => {
    let updatedDossiersAssocies = [...newCollaborateur.dossiers_assignes];

    if (event.target.checked) {
      console.log("Dossier ", index, "✅ Checkbox is checked");
      updatedDossiersAssocies.push(dossier.id);
      console.log("Dossier associés", updatedDossiersAssocies);
    } else {
      console.log("⛔️ Checkbox is NOT checked");
    }

    setNewCollaborateur({
      ...newCollaborateur,
      dossiers_assignes: updatedDossiersAssocies,
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verify all necessary fields are populated.
    const requiredFields = ["first_name", "last_name", "email", "password"];
    const areFieldsFilled = requiredFields.every(
      (field) =>
        newCollaborateur[field] &&
        newCollaborateur[field].toString().trim() !== ""
    );

    if (!areFieldsFilled) {
      toast.error("Tous les champs sont obligatoires.");
      return;
    }

    // Construct the main data payload as a JSON object.
    let dataPayload = {
      phone: newCollaborateur.phone,
      first_name: newCollaborateur.first_name,
      last_name: newCollaborateur.last_name,
      adresse: newCollaborateur.adresse,
      email: newCollaborateur.email,
      password: newCollaborateur.password,
      fonction: {id: newCollaborateur.fonction},
      is_active: newCollaborateur.is_active,
      encadreur_expert_comptable: newCollaborateur.encadreur_expert_comptable,
      dossiers_assignes: newCollaborateur.dossiers_assignes,
      is_superuser: newCollaborateur.is_superuser,
    };
    try {
      await createCollaborateur(dataPayload, {
        headers: {"Content-Type": "application/json"},
      }).unwrap();
      refetch();
      toast.success("Collaborateur ajoutée avec succès");
      onDossierCreated();
      navigate("/gestion_utilisateurs/");
    } catch (error) {
      console.error("Requête échouée:", error);
      toast.error(`Erreur: ${error.data?.message || error.message}`);
    }
  };

  return (
    <>
      <GeneriqueModal
        onClose={onClose}
        isOpen={isOpen}
        title="Ajouter un collaborateur">
        <Container fluid className="container-style py-3">
          <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column align-items-center gap-3">
              <div className="w-100 d-flex flex-column gap-2">
                <Row className="row-gap-2">
                  <Col sm={6}>
                    <Form.Group controlId="formLastName">
                      <Form.Label className="mb-2">Nom</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        value={newCollaborateur.last_name}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={6}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label className="mb-2">Prénom(s)</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={newCollaborateur.first_name}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* ... Other input fields ... */}
                <Row className="row-gap-2">
                  <Col sm={6}>
                    <Form.Group controlId="formEmail">
                      <Form.Label className="mb-2">Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={newCollaborateur.email}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="formPassword">
                      <Form.Label className="mb-2">Mot de passe</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={newCollaborateur.password}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="row-gap-2">
                  <Col sm={6}>
                    <Form.Group controlId="formPhone">
                      <Form.Label className="mb-2">Phone</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={newCollaborateur.phone}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                        maxLength={15}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    {/* Placeholder for 'Categorie' */}
                    <Form.Group controlId="formAdresse">
                      <Form.Label className="mb-2">Adresse</Form.Label>
                      <Form.Control
                        type="text"
                        name="adresse"
                        value={newCollaborateur.adresse}
                        onChange={handleInputChange}
                        className="rounded rounded-pill"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="row-gap-2">
                  <Col sm={12}>
                    <Form.Group controlId="formFonction">
                      <Form.Label className="mb-2">Fonction</Form.Label>
                      <Form.Control
                        as="select"
                        name="fonction"
                        value={newCollaborateur.fonction}
                        onChange={handleInputChange}
                        className="rounded rounded-pill">
                        <option value="">- Sélectionnez -</option>
                        {roles?.map((role, index) => (
                          <option value={role.id} key={index}>
                            {role.title}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-none">
                  <div style={{margin: "10px 0px", fontWeight: "700"}}>
                    Les dossiers à assigner
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      flexWrap: "wrap",
                      gap: 6,
                    }}>
                    {dossiersExpertResponse?.map((dossier, index) => {
                      return (
                        <DossierCheckBox
                          key={index}
                          dossier={dossier}
                          index={index}
                          handleDossiersChecked={handleDossiersChecked}
                        />
                      );
                    })}
                  </div>
                </div>

                {/*<Row className="row-gap-2">
                          <Col sm={12}>
                            <Form.Group controlId="formFonction">
                              <Form.Label className="mb-2">Dossiers Assignés</Form.Label>
                              <Form.Control
                                as="select"
                                name="dossiers_assignes"
                                value={newCollaborateur.dossiers_assignes}
                                onChange={handleInputChange}
                                disabled={isLoadingExpertDossiers}
                                className="rounded rounded-pill"
                              >
                                <option  value=''>
                                      - Sélectionner un dossier client
                                </option>
                                {dossiersExpertResponse.map((dossier) => {
                                  return(
                                    <option key={dossier.id} value={dossier.id}>
                                      {dossier.nom_entreprise}
                                    </option>
                                  )
                                })}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>*/}
              </div>
              <Row className="mt-2">
                <Col sm={12} className="text-center">
                  <div className="d-flex gap-3">
                    <button
                      onClick={onClose}
                      className="btn btn-danger rounded rounded-pill px-4">
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary rounded rounded-pill px-4"
                      style={{
                        backgroundColor: "#068f7a",
                        borderColor: "#068f7a",
                      }}
                      disabled={
                        !newCollaborateur.first_name ||
                        !newCollaborateur.last_name ||
                        !newCollaborateur.email ||
                        !newCollaborateur.password ||
                        !newCollaborateur.fonction ||
                        isLoading
                      } // Add other fields as necessary
                    >
                      {isLoading ? "Chargement" : "Enregistrer"}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            {/* ... Other input fields ... */}
          </Form>
        </Container>
      </GeneriqueModal>
    </>
  );
}
