import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import HeaderFirst from "utils/HeaderFirst";
import HeaderButton from "utils/headerButton";
import CustomBootstrapTable from "utils/reactTable";
import arrow from "../../image/arrow-left.png";
import { useNavigate, useParams } from "react-router-dom";
import "./client.css";
import TablePaginators from "components/Pagination/TablePaginator";
import { toast } from "react-toastify";
import useJournalData from "./useJournalData";
import { useGetCodesQuery } from "actions/comptabilite/queries";
import { useGetCodeJournalQuery } from "actions/sage_clone/code_journaux/queries";
import ModalJournal from "./ModalJournal";
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/userSliceWithTokenValidation'; // Adjust according to your project structure
import { usePermissions } from "hooks/usePermissions";
import ButtonWhite from "components/buttons/ButtonWhite";
//import ModalDisplayJournal from "./ModalDisplayJournal";
//import { SAISIE_PATH } from "routes/navigation/navigationPaths";
// import axios from 'axios';
// import { useGetClientQuery } from 'actions/dossiers/queries';

const Comptabilite = () => {
  const { clientID: dossier_id } = useParams();
  const [serachTerm, setSearchedTerm] = useState("");
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const { updatedJournal, handleUpdateJournal, handleDeleteJournal, refetch } =
    useJournalData(dossier_id);
  const [viewArchived, setViewArchived] = useState(false);
  const userStatus = useSelector(getUser);
  const permissions = usePermissions();

  //Les codes
  const { data: codesResponse = [], isLoading: isLoadingResponse } =
    useGetCodesQuery({ dossier_client: dossier_id });// L'objectif est de recupérer le plan comptable associé à un dossier

  const {
    data: codeJournalResponse = [],
    isLoading: isLoadingcodeJournals,
  } = useGetCodeJournalQuery({ comptable: userStatus?.id });


  if (!isLoadingResponse) console.log("Mes codes ordered: ", codesResponse);
  if (!isLoadingcodeJournals) console.log("Mes codes journaux: ", codeJournalResponse.data);

  console.log("Mes journalesss: ", updatedJournal);
  const filteredData = updatedJournal.filter((journal) =>
    journal?.reference?.toLowerCase().includes(serachTerm.toLowerCase())
  );

  const navigate = useNavigate();

  const currentPageData = filteredData.slice(
    paginationIndex * pageSize,
    (paginationIndex + 1) * pageSize
  );

  const handleActionSelected = (action) => {
    if (action === "archive") {
      setViewArchived(!viewArchived); // Toggle between viewing archived and active depenses
    } else {
      console.log(`Action selected: ${action}`);
      // Handle other actions as necessary
    }
  };

  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const handleSearchChange = (newSearchTerm) => {
    setSearchedTerm(newSearchTerm);
  };

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row.id]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const rowEvents = {
    onClick: (e, row) => {
      console.log("Table row clicked:", row); // Check if this logs
      handleRowClick(row);
    },
  };

  const columns = [
    {
      dataField: "numero_facture",
      text: "N°Facture",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "reference",
      text: "Référence",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "date",
      text: "Date Echéance",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "total_credits",
      text: "Crédits",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
    {
      dataField: "total_debits",
      text: "Débits",
      formatter: (cell, row) => (
        <div onClick={() => handleRowClick(row)}>{cell}</div>
      ),
    },
  ];

  const handleDossierCreated = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);


  const closeModal = () => {
    setIsModalOpen(false);

  };

  const openModal = () => {
    setIsModalOpen(true);
    refetch()
  };


  useEffect(() => {
    //
  }, []);

  const nom = (
    <div style={{ display: "flex" }}>
      <h4 className="titre-dossier">les écritures comptables</h4>
      <span className="nbrDossier">{filteredData?.length}</span>
    </div>
  );

  const description = <div>Voici tous vos journaux</div>;

  const handleRowClick = (row) => {
    const clickedRowId = row?.id;
    console.log(clickedRowId);
  };

  const onEyesClick = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const detailClientURL = `details/${selectedRowId}/`;
      navigate(detailClientURL);
      console.log(selectedRowId);
    }
  };

  const onClickDelete = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const journal = updatedJournal.find((d) => d.id === selectedRowId);
      if (!journal) {
        toast.error("journal non trouvé.");
        return;
      }

      try {
        const newArchivedStatus = !journal.active;
        await handleDeleteJournal(selectedRowId);
        /*await handleUpdateClient(selectedRowId, {
          active: newArchivedStatus,
          archived: true
        });*/
        setSelectedRows([]);
        toast.info(
          `journal ${newArchivedStatus ? "archivé" : "désarchivé"} avec succès.`
        );
      } catch (error) {
        toast.error(
          "Une erreur est survenue lors de la mise à jour du journal."
        );
        console.error("Erreur lors de la mise à jour du journal :", error);
      }
    } else {
      toast.warning(
        "Veuillez sélectionner exactement une journal à archiver ou désarchiver."
      );
    }
  };

  const onClickArchive = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const journal = updatedJournal.find((d) => d.id === selectedRowId);
      if (!journal) {
        toast.error("journal non trouvé.");
        return;
      }

      try {
        const newArchivedStatus = !journal.active;
        await handleUpdateJournal(selectedRowId, {
          active: newArchivedStatus,
          archived: true,
        });
        setSelectedRows([]);
        toast.info(
          `journal ${newArchivedStatus ? "archivé" : "désarchivé"} avec succès.`
        );
      } catch (error) {
        toast.error(
          "Une erreur est survenue lors de la mise à jour du journal."
        );
        console.error("Erreur lors de la mise à jour de la journal :", error);
      }
    } else {
      toast.warning(
        "Veuillez sélectionner exactement une journal à archiver ou désarchiver."
      );
    }
  };

  const onActivedClick = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const journal = updatedJournal.find((d) => d.id === selectedRowId);
      if (!journal) {
        toast.error("journal non trouvé.");
        return;
      }

      try {
        const newActiveStatus = !journal.active;
        await handleUpdateJournal(selectedRowId, {
          active: newActiveStatus,
        });
        setSelectedRows([]);
        toast.info(
          `journal ${newActiveStatus ? "activé" : "désactivé"} avec succès.`
        );
      } catch (error) {
        toast.error(
          "Une erreur est survenue lors de la mise à jour de la journal."
        );
        console.error("Erreur lors de la mise à jour de la journal :", error);
      }
    } else {
      toast.warning(
        "Veuillez sélectionner exactement une journal à activer ou désactiver."
      );
    }
  };

  const methods = useForm();
  return (
    <div
      className="global"
      style={{
        backgroundColor: "white",
        minHeight: "500px",
        borderRadius: "20px",
        padding: "15px",
      }}>
      <div
        className="header pb-3"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          // padding: "13px",
        }}>
        <HeaderFirst nom={nom} description={description} />
        {permissions.MANAGE_ACCOUNTING_ENTRIES && (
          <div>
            <ButtonWhite text={"+ Ecritures comptables"} onClick={openModal} />
          </div>
        )}


        {isModalOpen && (
          <FormProvider {...methods}>
            <ModalJournal
              isOpen={isModalOpen}
              onClose={closeModal}
              onDossierCreated={handleDossierCreated}
              journals={updatedJournal}
              codes={codesResponse || []}
              codeJournalResponse={codeJournalResponse.data || []}
              refetch={refetch}
              dossier_id={dossier_id}>
              <h2
                style={{ color: " #009688", fontSize: "20px", fontWeight: 700 }}>
                <span>
                  {" "}
                  <img src={arrow} />
                </span>{" "}
                &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
              </h2>
            </ModalJournal>
          </FormProvider>
        )}
      </div>
      {permissions.MANAGE_ACCOUNTING_ENTRIES && (
        <HeaderButton
          placeholder={"Rechercher une journal"}
          onSearchChange={handleSearchChange}
          isButtonsEnabled={selectedRows.length > 0}
          onClickArchive={onClickArchive}
          onActivedClick={onActivedClick}
          onEyesClick={onEyesClick}
          onClickDelete={onClickDelete}
        />
      )}

      <div>
        {updatedJournal ? (
          <CustomBootstrapTable
            keyField="id"
            data={currentPageData?.filter((journal) =>
              journal.reference.toLowerCase().includes(serachTerm.toLowerCase())
            )}
            columns={columns}
            selectedRows={selectedRows}
            onSelectRow={handleRowSelect}
            wrapperClasses="table-wrapper"
            rowEvents={rowEvents}
          />
        ) : (
          <p>Chargement des données...</p>
        )}
      </div>
      {currentPageData && currentPageData.length > 0 && (
        <TablePaginators
          paginationIndex={paginationIndex}
          setPaginationIndex={handlePageChange}
          dataLength={filteredData.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onActionSelected={handleActionSelected}
          isViewingArchived={viewArchived}
        />
      )}
    </div>
  );
};
export default Comptabilite;
