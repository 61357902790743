import React from "react";

const PermissionCardLoader = () => (
    <div className="ms-0 ps-0 col-lg-6 col-12 mb-3">
        <div
            className="card h-lg-75 h-100 px-4 py-3 m-1 d-flex align-items-center flex-row justify-content-between gap-2"
            style={{
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#f4f4f4",
                opacity: "0.5",
            }}>
            <div className="flex flex-column">
                {/* Permission Name Loader */}
                <div
                    className="placeholder-glow"
                    style={{
                        width: "150px",
                        height: "15px",
                        borderRadius: "4px",
                        marginBottom: "10px",
                    }}>
                    <span className="placeholder col-12"
                        style={{
                            width: "150px",
                            height: "15px",
                            borderRadius: "4px",
                            marginBottom: "10px",
                        }}></span>
                </div>

                {/* Permission Description Loader */}
                <div
                    className="placeholder-glow"
                    style={{
                        width: "200px",
                        borderRadius: "4px",
                    }}>
                    <span className="placeholder col-12"
                        style={{
                            width: "200px",
                            height: "12px",
                            borderRadius: "4px",
                        }}></span>
                </div>
            </div>

            {/* Switch Loader */}
            <div className="form-check form-switch d-flex align-items-center gap-2">
                {/* Switch toggle placeholder */}
                <div
                    className="placeholder-glow"
                    style={{
                        width: "50px",
                        borderRadius: "15px",
                    }}>
                    <span className="placeholder col-12"
                        style={{
                            width: "50px",
                            height: "20px",
                            borderRadius: "15px",
                        }}></span>
                </div>
            </div>
        </div>
    </div>
);

export default PermissionCardLoader;
