import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { HiOutlineChevronDown, HiOutlineChevronRight } from "react-icons/hi";
import { useLocation } from "react-router-dom";

const LinkItem = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 5px;
  padding-left: 15px;
  height: 60px;
  overflow: hidden;
  text-decoration: none;
  color: ${({ color }) => color};
  &:hover,
  :hover * {
    color: ${({ coloronhover }) => coloronhover};
    background-color: ${({ activelinkbgcolor }) => activelinkbgcolor};
  }
  &.active,
  &.active * {
    color: ${({ coloronactive }) => coloronactive};
    background-color: ${({ activelinkbgcolor }) => activelinkbgcolor};
  }
  * {
    color: ${({ color }) => color};
  }
`;

const SidebarItem = ({
  linkItem,
  activeLinkColor,
  activeLinkBgColor,
  colorOnHover,
  linkColor,
  openedMenu,
  inDossier,
  dossier,
}) => {
  const { path, label } = linkItem;
  const [show, setShow] = useState(false);
  //const [afficheMoi, setAfficheMoi] = useState(false)
  //const [zoneColor, setZoneColor] = useState()

  const pathname = useLocation().pathname;

  useEffect(() => {
    if (linkItem.multiple) {
      const list = linkItem.sous_links.map((element) => {
        if (inDossier) {
          return `/client/${dossier}/` + element.path;
        } else {
          return element.path;
        }
      });

      //const second_list =  linkItem.sous_links.map((element) => "/"+element.path)

      if (list.includes(pathname)) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, [pathname]);

  /*const handleClick = (e) => {
    e.stopPropagation();
    setShow(c => !c)
  }*/
  return (
    <>
      {linkItem.multiple ? (
        <>
          {
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShow((c) => !c);
              }}
              style={{
                background: `${show ? "rgba(00,96,88,0.8)" : ""}`,
                marginBottom: `${show ? "20px" : ""}`,
              }}>
              <Lien
                linkItem={linkItem}
                activeLinkColor={activeLinkColor}
                activeLinkBgColor={activeLinkBgColor}
                colorOnHover={colorOnHover}
                linkColor={linkColor}
                openedMenu={openedMenu}
                path={path}
                label={label}
                multiple={linkItem.multiple}
                show={show}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  paddingLeft: "30px",
                  color: "white",
                }}>
                {show &&
                  linkItem.sous_links.map((link, i) => {
                    return (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setShow(true);
                        }}
                        key={i}>
                        <Lien
                          linkItem={link}
                          activeLinkColor={activeLinkColor}
                          activeLinkBgColor={activeLinkBgColor}
                          colorOnHover={colorOnHover}
                          linkColor={linkColor}
                          openedMenu={openedMenu}
                          path={link.path}
                          label={link.label}
                          show={show}
                          key={i}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          }
        </>
      ) : (
        <Lien
          linkItem={linkItem}
          activeLinkColor={activeLinkColor}
          activeLinkBgColor={activeLinkBgColor}
          colorOnHover={colorOnHover}
          linkColor={linkColor}
          openedMenu={openedMenu}
          path={path}
          label={label}
          multiple={linkItem.multiple}
          show={show}
        />
      )}
    </>
  );
};

// Add PropTypes validation for the props
SidebarItem.propTypes = {
  linkItem: PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
  }).isRequired,
  activeLinkColor: PropTypes.string.isRequired,
  activeLinkBgColor: PropTypes.string.isRequired,
  colorOnHover: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
};

export default SidebarItem;

const Lien = ({
  linkItem,
  activeLinkColor,
  activeLinkBgColor,
  colorOnHover,
  linkColor,
  openedMenu,
  path,
  label,
  show,
}) => {
  return (
    <LinkItem
      color={linkColor}
      coloronhover={colorOnHover}
      // coloronactive={'rgba(0,5,10,.5)'}
      coloronactive={activeLinkColor}
      activelinkbgcolor={activeLinkBgColor}
      to={path}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 5,
          color: `${show ? "white" : ""}`,
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            color: `${show ? "white" : ""}`,
          }}>
          <div
            style={{
              height: 35,
              width: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <linkItem.icon size={20} className="icon" />
          </div>
          <p
            style={{
              fontSize: 14,
              // fontStyle: normal,
              fontWeight: 600,
              lineHeight: 35 /* 171.429% */,
              marginBottom: 2,
              display: openedMenu ? "none" : "block",
              color: `${show ? "white" : ""}`,
            }}>
            {label}
          </p>
        </div>

        {linkItem.multiple && (
          <div>
            <div
              style={{
                height: 15,
                width: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {show ? (
                <HiOutlineChevronDown size={16} className="icon" />
              ) : (
                <HiOutlineChevronRight size={16} className="icon" />
              )}
            </div>
          </div>
        )}
      </div>
    </LinkItem>
  );
};
