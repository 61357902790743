import ButtonGreen from 'components/buttons/ButtonGreen';
import React from 'react';
import * as XLSX from 'xlsx';

const sheetConfigs = [
  {
    sheetName: 'TitleDetails',
    sheetData: [
      { nom: 'Title1', telephone: 'Details1', adresse: 'Details1', email: 'Details1' },
    ],
  },

];

const DownloadExcel = () => {
  const handleDownload = () => {
    const wb = XLSX.utils.book_new();


    sheetConfigs.forEach(({ sheetData, sheetName }) => {
      const ws = XLSX.utils.json_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });

    const filename = `Exemple_Structure${Date.now()}.xlsx`;
    XLSX.writeFile(wb, filename, { bookType: 'xlsx' });
  };

  return (

    <ButtonGreen
      text="Modèle du Fichier"
      onClick={handleDownload} />

  );
};

export default DownloadExcel;