/* eslint-disable react/react-in-jsx-scope */
import HomePage from "pages/Home/HomePage";
import {
  ACCEUIL_PATH,
  ARCHIVE_PATH,
  PRODUCTS_PATH,
  COLLABORATEURS,
  DESACTIVES_PATH,
  DETAIL_COLLABORATEURS_PATH,
  SOLDES,
  DOSSIER_PATH,
  GENERATE_ETATS,
  SAISIE_MANUELLE,
  IMPORT_FICHIERS,
  GESTION_TIERS,
  ROLE_AND_PERMISSIONS,
  ADD_ROLE,
  PARAMETRES_CABINET,
  FOURNISSEURS,
  DETAIL_FOURNISSEURS_PATH,
  CLIENT,
  DETAIL_CLIENTS_PATH,
  SALARIES_CABINET,
  DETAIL_SALARIES_PATH_CABINET,
  CODE_JOURNAL,
  DETAIL_CODE_JOURNAL_PATH,
  JOURNAL_ACHAT,
  JOURNAL_ACHAT_PATH,
  SAISIE_PATH,
  DISPLAYJOURNAL_PATH,

  /*DETAIL_CLIENTS_PATH,
  DETAIL_FOURNISSEURS_PATH,
  DOSSIER_PATH,
  FOURNISSEURS,*/
  // PARAMETRE_PATH,
  /*SOLDES,
  VENTE,
  COMPTABILITE*/
  // AJOUTE_VENTE
  PARAMETRES_DOSSIER,
  GRAND_LIVRE,
  BALANCE,
  COMPTE_RESULTATS,
  BALANCE_GENERAL,
  BALANCE_AUXILIAIRE,
  BALANCE_AGEE,
  COMPTABILITE,
  BILAN,
  OCR,
  OCR_VIEW_INVOICE,
} from "../navigationPaths";
import RequireAuth from "layouts/RequireAuth";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ForderListPage from "pages/ForderPage/ForderListPage";
import {Navigate} from "react-router-dom";
import DosierDesactivePage from "pages/Desactives/DosierDesactivePage";
import DossierArchivePage from "pages/DossierArchive/DossierArchivePage";
// Layouts
import DashboardAppShellCabinet from "layouts/AppShells/cabinet/DashboardAppShellCabinet";
import DashboardAppShellCabinetClient from "layouts/AppShells/cabinet/DashboardAppShellCabinetClient";

import Paramètres from "pages/Cabinet/Paramètres";
import Gestion from "pages/Cabinet/Gestion";
import CollaborateurPage from "pages/Cabinet/Collaborateur/CollaborateurPage";
import CollaborateurDetails from "pages/Cabinet/Collaborateur/CollaborateurDetails";

import Accueil from "pagesDossierClient/accueil/accueil";
import GenererEtats from "pagesDossierClientCabinet/GenererEtats/GenererEtats";
import SaisieManuelle from "pagesDossierClientCabinet/SaisieManuelle/SaisieManuelle";
import ImportFichiers from "pagesDossierClientCabinet/ImportFichiers/ImportFichiers";
import GestionTiers from "pagesDossierClientCabinet/GestionTiers/GestionTiers";
import RoleAndPermissionsPage from "pages/Cabinet/RoleAndPermissions/RoleAndPermissionsPage";
import AddRole from "pages/Cabinet/RoleAndPermissions/AddRole";

import Fournisseurs from "pagesDossierClient/fournisseurs/fournisseurs";
import DetailFournisseurs from "pagesDossierClient/fournisseurs/detailFournisseurs";
import ClientPage from "pagesDossierClient/Client/ClientPage";
import ClientDetails from "pagesDossierClient/Client/ClientDetails";

import SalariePage from "pagesDossierClientCabinet/Salaries/SalariePage";
import SalarieDetails from "pagesDossierClientCabinet/Salaries/SalarieDetails";
import CodeJournaux from "pagesSAGECOMPTA/codeJournaux/CodeJournaux";
import DetailCodeJournal from "pagesSAGECOMPTA/codeJournaux/DetailCodeJournal";
//import JournalAchat from "pagesSAGECOMPTA/journalAchat/JournalAchat";
//import DetailJournal from "pagesSAGECOMPTA/journalAchat/DetailJournal";
import Saisie from "pagesSAGECOMPTA/Saisie/Saisie";
import DisplayJournal from "pagesSAGECOMPTA/DisplayJournal/DisplayJournal";
import JournalAchat_CodeJournal from "pagesSAGECOMPTA/journalAchat/journalAchat_CodeJournal";
import DisplayJournal_CodeJournal from "pagesSAGECOMPTA/journalAchat/DetailJournal_CodeJournal";
//import JournalAchat from "pagesSAGECOMPTA/journalAchat/JournalAchat";
import Parametres_dossier from "pagesDossierClientCabinet/Paramètres/Parametres_dossier";
import GrandLivre from "pagesSAGECOMPTA/Grand_Livre/GrandLivre";

import CompteResultats from "pagesDossierClient/comptabilite/components/CompteResultats";
import Balance from "pagesSAGECOMPTA/Balance/Balance";
import BalanceGenerale from "pagesSAGECOMPTA/Balance/BalanceGenerale";
import BalanceAgee from "pagesSAGECOMPTA/Balance/BalanceAgee";
import BalanceAuxiliaire from "pagesSAGECOMPTA/Balance/BalanceAuxiliaire";
import Comptabilite from "pagesSAGECOMPTA/comptabilite/Comptabilite";
import ComptabiliteDetail from "pagesSAGECOMPTA/comptabilite/ComptabiliteDetail";
import Bilan from "pagesSAGECOMPTA/Bilan/Bilan";
import OcrPage from "pagesSAGECOMPTA/OCR/OcrPage";
import ViewInvoice from "pagesSAGECOMPTA/OCR/ViewInovoice";
import SaisieOCR from "pagesSAGECOMPTA/OCR/Saisie/SaisieOCR";
// import { Navigate } from "react-router-dom";

export const privateRoutesCabinet = [
  {
    path: ACCEUIL_PATH,
    errorElement: <ErrorBoundary />,

    element: <RequireAuth />,
    children: [
      {
        path: "*",
        element: <Navigate to={ACCEUIL_PATH} />,
      },
      {
        path: "",
        element: <DashboardAppShellCabinet />,
        children: [
          {
            path: "",
            element: <HomePage />,
          },
          {
            path: PRODUCTS_PATH,
            element: <ForderListPage />,
            children: [
              {
                path: "",
                element: <ForderListPage />,
              },
              {
                path: ":produitId",
                element: <p>Vous venez d&apos;ajouter un produit</p>,
              },
            ],
          },
          {
            path: CODE_JOURNAL,
            element: <CodeJournaux />,
          },
          {
            path: `${CODE_JOURNAL}/details/${DETAIL_CODE_JOURNAL_PATH}`,
            element: <DetailCodeJournal />,
          },
          {
            path: DESACTIVES_PATH,
            element: <DosierDesactivePage />,
          },
          {
            path: ARCHIVE_PATH,
            element: <DossierArchivePage />,
          },
          {
            path: COLLABORATEURS,
            element: <CollaborateurPage />,
          },
          {
            path: ROLE_AND_PERMISSIONS,
            element: <RoleAndPermissionsPage />,
          },
          {
            path: `${ROLE_AND_PERMISSIONS}${ADD_ROLE}`,
            element: <AddRole />,
          },
          {
            path: `${COLLABORATEURS}details/${DETAIL_COLLABORATEURS_PATH}`,
            element: <CollaborateurDetails />,
          },
          {
            path: PARAMETRES_CABINET,
            element: <Paramètres />,
          },
          {
            path: "/gestion",
            element: <Gestion />,
          },
        ],
      },
      {
        path: DOSSIER_PATH,
        element: <DashboardAppShellCabinetClient />,
        children: [
          {
            path: SOLDES,
            element: <Accueil />,
          },
          {
            path: GENERATE_ETATS,
            element: <GenererEtats />,
          },
          {
            path: OCR,
            element: <OcrPage />,
          },
          {
            path: OCR_VIEW_INVOICE,
            element: <ViewInvoice />,
          },
          {
            path: `${OCR_VIEW_INVOICE}/:id`,
            element: <ViewInvoice />,
          },
          {
            path: `${OCR_VIEW_INVOICE}/:id/saisie/comptable`,
            element: <SaisieOCR />,
          },
          {
            path: SAISIE_MANUELLE,
            element: <SaisieManuelle />,
          },

          {
            path: IMPORT_FICHIERS,
            element: <ImportFichiers />,
          },
          //************* SAGE ROUTE************************ */
          {
            path: COMPTABILITE,
            element: <Comptabilite />,
          },

          {
            path: JOURNAL_ACHAT,
            element: <JournalAchat_CodeJournal />,
          },
          {
            path: GRAND_LIVRE,
            element: <GrandLivre />,
          },
          {
            path: BALANCE,
            element: <Balance />,
          },
          {
            path: BILAN,
            element: <Bilan />,
          },
          {
            path: BALANCE_GENERAL,
            element: <BalanceGenerale />,
          },
          {
            path: BALANCE_AUXILIAIRE,
            element: <BalanceAuxiliaire />,
          },
          {
            path: BALANCE_AGEE,
            element: <BalanceAgee />,
          },
          {
            path: COMPTE_RESULTATS,
            element: <CompteResultats />,
          },

          {
            path: DISPLAYJOURNAL_PATH,
            element: <DisplayJournal />,
          },
          {
            path: `${JOURNAL_ACHAT}details/${JOURNAL_ACHAT_PATH}`,
            element: <DisplayJournal_CodeJournal />,
          },
          //************************************************* */
          {
            path: GESTION_TIERS,
            element: <GestionTiers />,
          },
          {
            path: PARAMETRES_DOSSIER,
            element: <Parametres_dossier />,
          },
          {
            path: FOURNISSEURS,
            element: <Fournisseurs />,
          },
          {
            path: `${FOURNISSEURS}details/${DETAIL_FOURNISSEURS_PATH}`,
            element: <DetailFournisseurs />,
          },
          {
            path: CLIENT,
            element: <ClientPage />,
          },
          {
            path: `${CLIENT}details/${DETAIL_CLIENTS_PATH}`,
            element: <ClientDetails />,
          },
          {
            path: SALARIES_CABINET,
            element: <SalariePage />,
          },
          {
            path: `${SALARIES_CABINET}details/${DETAIL_SALARIES_PATH_CABINET}`,
            element: <SalarieDetails />,
          },
        ],
      },
      {
        path: `${DOSSIER_PATH}/${SAISIE_PATH}`,
        element: <Saisie />,
      },
      {
        path: `${DOSSIER_PATH}/${COMPTABILITE}details/${DETAIL_CODE_JOURNAL_PATH}`,
        element: <ComptabiliteDetail />,
      },
      {
        path: "/nope",
        element: <p>Page vide</p>,
      },
    ],
  },
];
