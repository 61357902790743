import { useGetCompteResultatsQuery } from 'actions/dossiers/queries';
import ButtonWhite from 'components/buttons/ButtonWhite';
import React, { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useParams } from 'react-router-dom';
import { usePDF } from 'react-to-pdf';
import HeaderFirst from 'utils/HeaderFirst';
import '../../../components/Modal.css'
import { usePermissions } from 'hooks/usePermissions';
import ButtonGreen from 'components/buttons/ButtonGreen';

export default function CompteResultats() {
    const { clientID: id } = useParams();
    const permissions = usePermissions();
    const today = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(today.getFullYear() - 1);
    const [dateDebut, setDateDebut] = useState(oneYearAgo.toISOString().split('T')[0]);
    const [dateFin, setDateFin] = useState(today.toISOString().split('T')[0]);

    const { data: resultats, isLoading, isError, error } = useGetCompteResultatsQuery(
        { id: id, dateDebut, dateFin },
        { skip: !dateDebut || !dateFin }
    );

    console.log(id);


    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
    const myRef = useRef(null);
    const tableRef = useRef(null);

    // Extraire en excel
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'CompteResultats table',
        sheet: 'CompteResultats'
    });

    useEffect(() => {
        tableRef.current = myRef.current;
        targetRef.current = myRef.current;
    }, [myRef.current]);

    return (
        <div
            style={{
                backgroundColor: 'white',
                minHeight: '500px',
                borderRadius: '20px',
                padding: '15px',
            }}
        >
            <HeaderFirst
                nom={
                    <h4 className="titre-dossier">
                        Compte de résultat
                    </h4>
                }
                description={
                    <div>
                        Vous pouvez ici visualiser la somme des résultats comptables de votre client.
                    </div>
                }
            />
            <div className='px-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                <div className="d-flex align-items-center gap-3">
                    <input
                        type="date"
                        value={dateDebut}
                        onChange={(e) => setDateDebut(e.target.value)}
                        placeholder="Sélectionner la date de début"
                        className='form-controll'
                    />
                    <input
                        type="date"
                        value={dateFin}
                        onChange={(e) => setDateFin(e.target.value)}
                        placeholder="Sélectionner la date de fin"
                        className='form-controll'
                    />
                </div>
                {permissions.DOWNLOAD_ACCOUNTING_STATEMENTS && (
                    <div className='d-flex align-items-center justify-content-end gap-3'>
                        <ButtonGreen onClick={onDownload} text={"Exporter en Excel"} />
                        <ButtonWhite onClick={() => toPDF()} text={"Télécharger PDF"} />
                    </div>
                )}
            </div>
            {isLoading && <p>Chargement...</p>}
            {isError && <p>Erreur : {error.message}</p>}
            {resultats && (
                <div ref={myRef} className='px-2'>
                    <div className="d-flex flex-column align-items-start justify-content-between gap-2">
                        <div className='col-12'>
                            <h5 className='mt-3'>Produits</h5>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th>Compte</th>
                                        <th>Libellé</th>
                                        <th>Débit</th>
                                        <th>Crédit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(resultats.detailed_revenues).map((key) => {
                                        const { entries } = resultats.detailed_revenues[key];
                                        return entries.map((entry) => (
                                            <tr key={entry.id}>
                                                <td>{key}</td>
                                                <td>{entry.libelle}</td>
                                                <td>{entry.debit}</td>
                                                <td>{entry.credit}</td>
                                            </tr>
                                        ));
                                    })}
                                    <tr>
                                        <td colSpan="3">Total Produits</td>
                                        <td>{resultats.revenus}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='col-12'>
                            <h5 className='mt-3'>Charges</h5>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th>Compte</th>
                                        <th>Libellé</th>
                                        <th>Débit</th>
                                        <th>Crédit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(resultats.detailed_charges).map((key) => {
                                        const { entries } = resultats.detailed_charges[key];
                                        return entries.map((entry) => (
                                            <tr key={entry.id}>
                                                <td>{key}</td>
                                                <td>{entry.libelle}</td>
                                                <td>{entry.debit}</td>
                                                <td>{entry.credit}</td>
                                            </tr>
                                        ));
                                    })}
                                    <tr>
                                        <td colSpan="3">Total Charges</td>
                                        <td>{resultats.charges}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <h5 className='mt-3'>Résultat Net</h5>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr>
                                <td>Total Produits</td>
                                <td>{resultats.revenus}</td>
                            </tr>
                            <tr>
                                <td>Total Charges</td>
                                <td>{resultats.charges}</td>
                            </tr>
                            <tr>
                                <td>Résultat Net</td>
                                <td>{resultats.resultat_net}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

        </div>
    );
}
