import React, {  useState } from 'react';
import './file_ocr.css';
import HeaderButton from 'utils/headerButton';
import CustomBootstrapTable from 'utils/reactTable';
import HeaderFirst from 'utils/HeaderFirst';
import TablePaginators from 'components/Pagination/TablePaginator';
import ButtonWhite from 'components/buttons/ButtonWhite';
import { useNavigate, useParams } from 'react-router-dom';
import InvoiceUploadModal from './InoviceModal';
import { useGetOcrFactureQuery } from 'actions/orc/queries';

function OcrDashboard() {
  const { clientID: id } = useParams();

  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const { data: invoicesData, isLoading, refetch } = useGetOcrFactureQuery(id);

  const invoices = invoicesData?.data
    ? invoicesData?.data.map((invoice, index) => ({
      id: invoice.id,
      invoiceNumber: `FA${index + 1}`.padStart(10, '0'), // Mock invoice number
      date: new Date().toLocaleDateString(), // Mock date
      amount: 'N/A', // Placeholder for amount
      status: 'En attente', // Placeholder for status
      file: invoice.file,
    }))
    : [];

    console.log("Mes factures sont: ",invoicesData)
  
  /*useEffect(() => {
    refetch()
  },[invoicesData])*/

  // Filter invoices based on the search term
  const filteredInvoices = invoices.filter((invoice) =>
    invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleViewInvoice = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const id = selectedRowId;
      navigate(`view-invoice/${id}`);
    }
  };

  const currentPageData = filteredInvoices.slice(
    paginationIndex * pageSize,
    (paginationIndex + 1) * pageSize
  );

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row.id]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const handleAddInvoice = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      dataField: 'invoiceNumber',
      text: 'Numéro de Facture',
    },
    {
      dataField: 'date',
      text: 'Date de la Facture',
    },
    {
      dataField: 'amount',
      text: 'Montant',
    },
    {
      dataField: 'status',
      text: 'Statut',
    },
  ];

  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">Factures OCR</h4>
      <span className="nbrDossier">{filteredInvoices.length}</span>
    </div>
  );

  const description = <div>Liste de toutes les factures OCR extraites</div>;


  if (isLoading) {
    return <div>Chargement des factures...</div>;
  }

  /*if (error) {
    return <div>Erreur lors du chargement des factures.</div>;
  }*/
    //refetch();
  return (
    <div className="global" style={{ backgroundColor: 'white', minHeight: '500px', borderRadius: '20px', padding: '15px' }}>
      <div className="header mb-3" style={{ backgroundColor: 'white', borderRadius: '10px', display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
        <HeaderFirst nom={nom} description={description} />
        <ButtonWhite onClick={handleAddInvoice} text="+ Ajouter une facture OCR" />
      </div>
      {/* Invoice Modal for Adding OCR Invoice */}
      {isModalOpen && <InvoiceUploadModal onClose={closeModal} isOpen={isModalOpen} refetch={refetch} dossier_id={id} />}
      <HeaderButton
        placeholder="Rechercher une facture"
        onSearchChange={handleSearchChange}
        isButtonsEnabled={selectedRows?.length > 0}
        onEyesClick={handleViewInvoice}
      />
      <div>
        <CustomBootstrapTable
          keyField="id"
          data={currentPageData}
          columns={columns}
          selectedRows={selectedRows}
          onSelectRow={handleRowSelect}
          wrapperClasses="table-wrapper"
        />
      </div>
      {currentPageData && (
        <TablePaginators
          paginationIndex={paginationIndex}
          setPaginationIndex={handlePageChange}
          dataLength={filteredInvoices.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
}

export default OcrDashboard;
