import React from "react";
import "./Switch.css"

function Switch({ isOn, handleToggle, colorOne, colorTwo, switchId }) {
    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className="switch-checkbox"
                id={`switch-${switchId}`} // Id unique basé sur switchId
                type="checkbox"
            />
            <label
                style={{ background: isOn ? colorOne : colorTwo }}
                className="switch-label"
                htmlFor={`switch-${switchId}`} // Label fait référence à l'id unique
            >
                <span className={`switch-button`} />
            </label>
        </>
    )
}

export default Switch;