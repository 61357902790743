// Modal.js
import React from "react";
import "../../components/Modal.css";
// import { useDispatch } from "react-redux";
import {useFormContext} from "react-hook-form";
import {toast} from "react-toastify";
import {useCreateCodeJournalMutation} from "actions/sage_clone/code_journaux/mutations";
import {useGetCodeJournalQuery} from "actions/sage_clone/code_journaux/queries";
import {useSelector} from "react-redux";
import {getUser} from "redux/features/userSliceWithTokenValidation"; // Adjust according to your project structure
import GeneriqueModal from "components/GeneriqueModal";
import ButtonCancel from "components/buttons/ButtonCancel";
import ButtonGreen from "components/buttons/ButtonGreen";

const ModalCodeJournal = ({isOpen, onClose, onDossierCreated}) => {
  const userStatus = useSelector(getUser);
  const [creationCodeJournal, {isLoading}] = useCreateCodeJournalMutation();
  const {data: getCodeJournal, refetch} = useGetCodeJournalQuery({
    comptable: userStatus?.id,
  });

  console.log({getCodeJournal});

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useFormContext();

  const refetchData = async () => {
    try {
      // Utilisez l'async/await pour attendre la résolution de la requête de refetch
      const refetchResult = await refetch();

      // Assurez-vous que la requête de refetch s'est terminée avec succès avant de traiter les données
      if (refetchResult && refetchResult.status === "fulfilled") {
        console.log("Récupération des données réussie");
      } else {
        console.error("Échec du refetch des données");
      }
    } catch (error) {
      console.error("Erreur lors du refetch des données", error);
    }
  };

  const onSubmit = (data) => {
    const codeJournalData = {...data, comptable: userStatus?.id};
    creationCodeJournal(codeJournalData)
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.success("Création du code journal réussie !");
        reset();
        refetchData();
        onDossierCreated();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <GeneriqueModal
        className="modal-overlay"
        onClose={onClose}
        title={"Ajouter un code journal"}
        isOpen={isOpen}>
        <form
          className="codeJournal-form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="code_journal" className="form-label">
              Code Journal
            </label>
            <input
              className="form-controll"
              type="text"
              id="code_journal"
              placeholder="Code Journal"
              {...register("code_journal", {
                required: "Code Journal obligatoire",
              })}
            />
            {errors.code_journal && (
              <div className="text-danger">{errors.code_journal.message}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="type_journal" className="form-label">
              Intitulé du code
            </label>
            <input
              className="form-controll"
              type="text"
              id="type_journal"
              placeholder="Intitulé du Code"
              {...register("type_journal", {
                required: "Intitulé obligatoire",
              })}
            />
            {errors.type_journal && (
              <div className="text-danger">{errors.type_journal.message}</div>
            )}
          </div>

          <div className="modal-footer">
            <ButtonCancel text={"Annuler"} onClick={onClose} />
            <ButtonGreen
              text={isLoading ? "Chargement..." : "Enregistrer"}
              type={"submit"}
              disabled={isLoading}
            />
          </div>
        </form>
      </GeneriqueModal>
    </>
  );
};

export default ModalCodeJournal;
