import React from "react";

const RoleCardLoader = () => (
    <div
        className="card m-2 mb-4 p-4"
        style={{
            flex: 1,
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            height: "360px",
            fontFamily: "Montserrat",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
            backgroundColor: "#f4f4f4", // Light background for loader
            opacity: '0.5'
        }}>
        <div className="card-body">
            <div className="d-flex pb-2 justify-content-between align-items-center ">
                {/* Title Loader */}
                <h3
                    className="loader-title placeholder-glow"
                    style={{
                        width: "40%",
                        borderRadius: "4px"
                    }}
                ><span className="placeholder col-12" style={{
                    borderRadius: "4px",
                    height: "10px"
                }}></span></h3>
                {/* Collaborators Loader */}
                <div
                    className="loader-collaborators placeholder-glow"
                    style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                    }}
                ><span className="placeholder col-12" style={{
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                }}></span></div>
            </div>

            {/* Description Loader */}
            <div
                className="loader-description placeholder-glow"
                style={{
                    width: "100%",
                    height: "100px",
                    borderRadius: "4px",
                    marginTop: "15px",
                }}
            ><span className="placeholder col-12" style={{
                borderRadius: "4px",
                width: "100%",
                height: "100px",
            }}></span></div>

            {/* Tags Loader */}
            <div className="d-flex flex-wrap">
                {[1, 2, 3].map((_, index) => (
                    <span
                        key={index}
                        className="badge bg-secondary me-2 mb-2"
                        style={{
                            backgroundColor: "#ddd", // Light gray block simulating tags
                            width: "60px",
                            height: "20px",
                            borderRadius: "20px",
                        }}
                    ></span>
                ))}
            </div>
        </div>

        {/* Button Loader */}
        <div
            className="loader-button placeholder-glow"
            style={{
                width: "120px",
                height: "40px",
                borderRadius: "40px",
                position: "absolute",
                bottom: "30px",
                left: "7%",
            }}
        ><span className="placeholder col-12" style={{
            borderRadius: "40px",
            width: "120px",
            height: "40px",
        }}></span></div>
    </div>
);

export default RoleCardLoader;
