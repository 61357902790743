import React from "react";
import CollaborateursIcon from "./CollaborateursIcon";

const RoleCard = ({ title, description, tags, buttonColor, collaborateurs }) => (
  <div
    className="card m-0 mb-4 p-4"
    style={{
      flex: 1,
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      height: "360px",
      fontFamily: "Montserrat",
      display: "flex",
      flexDirection: "column", // Add this for flex layout
      justifyContent: "space-between", // Ensure content stays spaced
      position: "relative", // Needed for button positioning
    }}>
    <div className="card-body">
      <div className="d-flex pb-2 justify-content-between align-items-center ">
        <h5 className="card-title">{title}</h5>
        <CollaborateursIcon collaborateurs={collaborateurs} />
      </div>

      {/* Ensure the description has a fixed height and allows ellipsis */}
      <p
        className="card-text"
        style={{
          fontWeight: 400,
          fontSize: "15.4px",
          height: "180px", // Set a fixed height for the text block
          overflow: "hidden", // Hide overflow text
          textOverflow: "ellipsis", // Add ellipsis for overflowed text
          display: "-webkit-box",
          WebkitLineClamp: 4, // Limit text to 4 lines
          WebkitBoxOrient: "vertical",
        }}>
        {description}
      </p>

      {/* Tags should stay flex and wrap properly */}
      <div className="d-flex flex-wrap">
        {Array.isArray(tags) &&
          tags.map((tag, index) => (
            <span key={index} className="badge bg-secondary me-2 mb-2">
              {tag}
            </span>
          ))}
      </div>
    </div>

    {/* The button is kept at the bottom */}
    <button
      type="button"
      className="btn mt-3"
      style={{
        border: `2px solid ${buttonColor}`,
        padding: "5px 17px",
        borderRadius: "40px",
        fontWeight: 400,
        fontSize: "15px",
        cursor: "default",
        position: "absolute", // Position it relative to the card
        bottom: "30px", // Keep it slightly above the bottom
      }}>
      {title}
    </button>
  </div>
);

export default RoleCard;
