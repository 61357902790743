import {ApiMananger} from "actions/rtkquery";
import {
  ADD_FILE_OCR_API_ROUTE,
  ADD_OCR_API_ROUTE,
  GET_OCR_TO_EXTRACT,
} from "routes/api/endpoints";

const ocrApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    createOcr: build.mutation({
      query(body) {
        return {
          url: ADD_OCR_API_ROUTE,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_OCR", "dossie", "INVALIDATE_DOSSIER"],
    }),
    getOcrFacture: build.mutation({
      query: (formData) => ({
        url: GET_OCR_TO_EXTRACT,
        method: "POST",
        body: formData,
      }),
    }),
    uploadOcrFile: build.mutation({
      query: (formData) => ({
        url: ADD_FILE_OCR_API_ROUTE,
        method: "POST",
        body: formData,
        // Do not set 'Content-Type'; the browser will handle it when using FormData
      }),
    }),
  }),
});

export const {
  useCreateOcrMutation,
  useGetOcrFactureMutation,
  useUploadOcrFileMutation,
} = ocrApi;
