import useGetAuthenticate from "hooks/useIsAuthenticatedUser";
import React from "react";
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import {privateRoutes} from "./navigation/navigationRoutes/private";
import {privateRoutesCabinet} from "./navigation/navigationRoutes/privateCabinet";
import {publicRoutes} from "./navigation/navigationRoutes/public";
import {NOTFOUND_PATH} from "./navigation/navigationPaths";
import PageNotFound from "pages/NotFound/PageNotFound";
import useGetCurrentUser from "hooks/useGetCurrentUser";
// import { router } from "./navigation";

function Roots() {
  const isAuthenticated = useGetAuthenticate();

  const currentUser = useGetCurrentUser();

  console.log("Mys current user data are: ", currentUser?.status);

  const router = isAuthenticated
    ? currentUser?.status === "ENTREPRISE"
      ? createBrowserRouter([
          ...privateRoutes,
          {
            path: NOTFOUND_PATH,
            element: <PageNotFound />,
          },
        ])
      : createBrowserRouter([
          ...privateRoutesCabinet,
          {
            path: NOTFOUND_PATH,
            element: <PageNotFound />,
          },
        ])
    : createBrowserRouter([
        ...publicRoutes,
        {
          path: NOTFOUND_PATH,
          element: <PageNotFound />,
        },
      ]);
  return (
    <RouterProvider router={router} fallbackElement={<p>Chargement...</p>} />
  );
}

export default Roots;
