import React, { useState } from 'react';
import './fournisseurs.css';
import HeaderButton from 'utils/headerButton';
import CustomBootstrapTable from 'utils/reactTable';
import HeaderFirst from 'utils/HeaderFirst';
import { FormProvider, useForm } from 'react-hook-form';
import ModalFournisseur from './Modal';
import arrow from '../../image/arrow-left.png';
import { useNavigate, useParams } from 'react-router-dom';
import useFournisseurData from './useFournisseurData';
import { toast } from 'react-toastify';
import TablePaginators from 'components/Pagination/TablePaginator';
import ModalExcel from './ModalExcel/ModalExcel';
import { HiCloudUpload } from 'react-icons/hi';
import DownloadExcel from 'pagesSAGECOMPTA/FileExcel/FileExcel';
import ButtonWhite from 'components/buttons/ButtonWhite';
//import { HiCloudUpload } from "react-icons/hi";


function Fournisseurs() {
  const { clientID: dossier_id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const { updatedFournisseurs, handleUpdateFournisseur, handleDeleteFournisseur, refetch } =
    useFournisseurData(dossier_id);
  const [viewArchived, setViewArchived] = useState(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false)

  const filteredData = updatedFournisseurs
    .filter((fournisseur) =>
      viewArchived ? fournisseur.archived : !fournisseur.archived
    )
    .filter((fournisseur) =>
      fournisseur.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const currentPageData = filteredData.slice(
    paginationIndex * pageSize,
    (paginationIndex + 1) * pageSize
  );

  const handleActionSelected = (action) => {
    if (action === 'archive') {
      setViewArchived(!viewArchived);
    } else {
      console.log(`Action selected: ${action}`);
      // Handle other actions as necessary
    }
  };

  const handlePageChange = (newIndex) => {
    setPaginationIndex(newIndex);
  };

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  const handleRowSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((prev) => [...prev, row.id]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const onClickDetails = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const detailFournisseurURL = `details/${selectedRowId}/`;
      navigate(detailFournisseurURL);
    }
  };

  const onClickArchive = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const fournisseur = updatedFournisseurs.find(
        (f) => f.id === selectedRowId
      );
      if (!fournisseur) {
        toast.error('Fournisseur non trouvé.');
        return;
      }

      try {
        await handleUpdateFournisseur(selectedRowId, {
          archived: !fournisseur.archived,
        });
        toast.info(
          `Fournisseur ${fournisseur.archived ? 'désarchivé' : 'archivé'
          } avec succès.`
        );
      } catch (error) {
        toast.error(
          'Une erreur est survenue lors de la mise à jour du fournisseur.'
        );
      }
    } else {
      toast.warning(
        'Veuillez sélectionner exactement un fournisseur à archiver ou désarchiver.'
      );
    }
  };

  const onClickDelete = async () => {
    if (selectedRows.length === 1) {
      const selectedRowId = selectedRows[0];
      const fournisseur = updatedFournisseurs.find(
        (f) => f.id === selectedRowId
      );
      if (!fournisseur) {
        toast.error('Fournisseur non trouvé.');
        return;
      }

      try {
        await handleDeleteFournisseur(selectedRowId)
        toast.info(
          `Fournisseur ${fournisseur.archived ? 'désarchivé' : 'archivé'
          } avec succès.`
        );
      } catch (error) {
        toast.error(
          'Une erreur est survenue lors de la mise à jour du fournisseur.'
        );
      }
    } else {
      toast.warning(
        'Veuillez sélectionner exactement un fournisseur à archiver ou désarchiver.'
      );
    }
  };

  const columns = [
    {
      dataField: 'compte_comptable',
      text: 'N°Compte',
      formatter: (cell) => <div>{cell}</div>,
    },
    {
      dataField: 'nom',
      text: 'Nom',
      formatter: (cell) => <div>{cell}</div>,
    },
    {
      dataField: 'created_at',
      text: "Date d'ajout",
      formatter: (cell) => <div>{cell}</div>,
    },
    {
      dataField: 'telephone',
      text: 'Numéro de téléphone',
      formatter: (cell) => <div>{cell}</div>,
    },
    {
      dataField: 'email',
      text: 'E-mail',
      formatter: (cell) => <div>{cell}</div>,
    },
    {
      dataField: 'active',
      text: 'Statut',
      formatter: (cell) => (cell ? 'Actif' : 'Inactif'),
    },
  ];

  const handleDossierCreated = () => {
    setIsModalOpen(false);
  };

  const nom = (
    <div style={{ display: 'flex' }}>
      <h4 className="titre-dossier">Fournisseurs</h4>
      <span className="nbrDossier">{filteredData?.length}</span>
    </div>
  );

  const description = <div>Voici tous vos fournisseurs</div>;


  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalFileOpen(false)

  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsModalFileOpen(false);
  };

  const methods = useForm();

  return (
    <div
      className="global"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <div
        className="header mb-3"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <HeaderFirst nom={nom} description={description} />
        <div>
          <ButtonWhite
            text={
              <span style={ajoutFou}><HiCloudUpload className='fs-6' /> Importer des fournisseurs</span>
            }
            onClick={() => { setIsModalFileOpen(true); setIsModalOpen(false) }} />
        </div>
        <div>
          <ButtonWhite
            text="+ Ajouter un fournisseur"
            onClick={openModal} />
        </div>
        <DownloadExcel />
        {isModalFileOpen && (
          <FormProvider {...methods}>
            <ModalExcel
              isOpen={isModalFileOpen}
              onClose={closeModal}
              refetch={refetch}
            >
              <h2
                style={{ color: ' #009688', fontSize: '20px', fontWeight: 700 }}
              >
                <span>
                  {' '}
                  <img src={arrow} />
                </span>{' '}
                &nbsp;IMPORTER VOS FICHIERS CLIENTS
              </h2>
            </ModalExcel>
          </FormProvider>

        )}

        {isModalOpen && (
          <FormProvider {...methods}>
            <ModalFournisseur
              isOpen={isModalOpen}
              onClose={closeModal}
              onDossierCreated={handleDossierCreated}
              fournisseurs={updatedFournisseurs}
            >
              <h2 style={{ color: '#009688', fontSize: '20px', fontWeight: 700 }}>
                <img src={arrow} alt="Arrow" />
                &nbsp;CREER UN NOUVEAU DOSSIER FOURNISSEUR
              </h2>
            </ModalFournisseur>
          </FormProvider>
        )}
      </div>
      <HeaderButton
        placeholder={'Rechercher un fournisseur'}
        onSearchChange={handleSearchChange}
        isButtonsEnabled={selectedRows?.length > 0}
        onClickArchive={onClickArchive}
        onEyesClick={onClickDetails}
        onClickDelete={onClickDelete}
      />
      <div>
        <CustomBootstrapTable
          keyField="id"
          data={currentPageData}
          columns={columns}
          selectedRows={selectedRows}
          onSelectRow={handleRowSelect}
          wrapperClasses="table-wrapper"
        />
      </div>
      {currentPageData && currentPageData?.length > 0 && (
        <TablePaginators
          paginationIndex={paginationIndex}
          setPaginationIndex={handlePageChange}
          dataLength={filteredData.length}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onActionSelected={handleActionSelected}
          isViewingArchived={viewArchived}
        />
      )}
    </div>
  );
}

const ajoutFou = {
  fontFamily: 'Montserrat',
  fontWeight: '600',
  fontSize: '13px',
};

export default Fournisseurs;
