import React, {useRef} from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';
import "../DisplayJournal/style.css"
import {useEffect} from "react";
import { useLocation } from 'react-router-dom'
import HeaderFirst from 'utils/HeaderFirst'
import { useGetJournalEcrituresByCodeJournalIdQuery,} from 'actions/sage_clone/journaux/queries';
import { useParams } from 'react-router-dom';


export default function DisplayJournal_CodeJournal() {
  const { clientID,id: journalId } = useParams();
  const {state} = useLocation()

  const {data: livrejournal, isLoading:isLoadingCode,refetch} = useGetJournalEcrituresByCodeJournalIdQuery({
    id: journalId,dossier: clientID
  })

   // pour excel
   const tableRef = useRef(null);
   const { onDownload } = useDownloadExcel({
     currentTableRef: tableRef.current,
     filename: 'LivreJournal table',
     sheet: 'LivreJournal'
   })  
 
   // pour pdf
   const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
 
   const myRef = useRef(null);
 
 
   useEffect(function() {
     tableRef.current = myRef.current;
     targetRef.current = myRef.current;
   }, [myRef.current]);

   useEffect(() => {
    refetch()
   },[isLoadingCode,livrejournal])

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Livre Journal: <span style={{color:"#009688"}}>{state.type}</span>
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser l&apos;ensemble des écritures comptables de votre client.
          </div>
        }
      />

      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"10px"}}>
        <div>

        </div>
        <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
          <button onClick={onDownload}> Export excel </button>
          <button onClick={() => toPDF()}>Download PDF</button>
        </div>

      </div>


        
      <table id="customers" ref={myRef}>
        <tbody>
          <tr>
            <th>Date</th>
            <th>Compte général</th>
            <th>Compte Tiers</th>
            <th>Libelle</th>
            <th>Debit</th>
            <th>Credit</th>
          </tr>

          {
           livrejournal?.map((livres,index) => {
            return(
                <>
                  <tr className='livre' key={index} style={{backgroundColor:"rgba(0,0,0,0.5)", color:"white"}}>
                    <td>{livres.date}  {livres.numero_facture} {livres.reference} </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {
                    livres.ecritures.map((ecriture,i) => {
                      return (
                        <tr key={i}>
                          <td>{ecriture.date}</td>
                          <td>{ecriture.compte_general}</td>
                          <td>{ecriture.compte_tiers}</td>
                          <td>{ecriture.libelle}</td>
                          <td>{ecriture.debit}</td>
                          <td>{ecriture.credit}</td>
                        </tr>
                      )
                    })
                  }
                  <tr key={index+1} >
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td style={{fontWeight:"bold"}}>Total</td>
                    <td style={{fontWeight:"bold"}}>{livres.total_debits}</td>
                    <td style={{fontWeight:"bold"}}>{livres.total_credits}</td>
                  </tr>
                </>
            )
          })
          }
        
        </tbody>
        
      </table>


    </div>
  )
}
