// permissions.js
export const PERMISSIONS = {
  ACCESS_ALL_FILES: 1,
  MODIFY_ROLES_ADD_USERS: 2,
  ACCESS_DASHBOARD_DATA_REPORTS: 3,
  ACCESS_MODIFICATION: 4,
  VIEW_COLLABORATORS: 5,
  MANAGE_COLLABORATORS: 6,
  VIEW_SUPPLIERS: 7,
  MANAGE_SUPPLIERS: 8,
  VIEW_CLIENTS: 9,
  MANAGE_CLIENTS: 10,
  VIEW_EMPLOYEES: 11,
  MANAGE_EMPLOYEES: 12,
  VIEW_ACCOUNTING_ENTRIES: 13,
  MANAGE_ACCOUNTING_ENTRIES: 14,
  VIEW_ACCOUNTING_STATEMENTS: 15,
  DOWNLOAD_ACCOUNTING_STATEMENTS: 16,
  VIEW_ATTACHMENTS: 17,
  MANAGE_ATTACHMENTS: 18,
  VIEW_FOLDER_SETTINGS: 19,
  MANAGE_FOLDER_SETTINGS: 20,
};
