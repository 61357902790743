// usePermissions.js
import {useState, useEffect} from "react";
import {useGetCollaborateurIdQuery} from "actions/collaborateur/queries";
import {PERMISSIONS} from "utils/Permissions";
import useGetCurrentUser from "./useGetCurrentUser";

const usePermissions = () => {
  const [permissionsStatus, setPermissionsStatus] = useState({});
  const currentUser = useGetCurrentUser();

  // Debug: Log the current user's ID
  console.log("Current User ID:", currentUser?.id);

  // Fetch collaborator data based on the current user's ID
  const {
    data: collaborateurData,
    isLoading: isLoadingCollaborateur,
    refetch,
  } = useGetCollaborateurIdQuery({id: currentUser?.id});

  // Debug: Log the collaborator data
  console.log("Collaborateur Data:", collaborateurData);

  useEffect(() => {
    if (!currentUser || isLoadingCollaborateur) {
      setPermissionsStatus({});
      return;
    }

    // Refetch the data
    refetch();

    let effectivePermissions = [];

    // If the user is a collaborator, retrieve permissions from collaborator data
    if (currentUser.isCollaborateur) {
      effectivePermissions = collaborateurData?.data[0]?.permissions || [];
    }
    // If the user is an ExpertComptable, assign all permissions by default
    else if (currentUser.isExpertComptable) {
      effectivePermissions = Object.values(PERMISSIONS).map((id) => ({id}));
    }
    // Otherwise, use the permissions directly from the currentUser object
    else {
      effectivePermissions = currentUser?.permissions || [];
    }

    // Create an object to indicate whether each permission is active
    const permissionsCheck = Object.keys(PERMISSIONS).reduce((acc, key) => {
      acc[key] = effectivePermissions.some(
        (permission) => permission.id === PERMISSIONS[key]
      );
      return acc;
    }, {});

    setPermissionsStatus(permissionsCheck);
  }, [currentUser, collaborateurData, isLoadingCollaborateur]);

  return permissionsStatus;
};

export {usePermissions};
