// Modal.js
import React from 'react';
import './Modal.css';
import ReactDOM from "react-dom";

const GeneriqueModal = ({ isOpen, onClose, title, children }) => {
    return (
        <>
            {isOpen && ReactDOM.createPortal(
                <div className="modal-overlay px-4" onClick={onClose}>
                    <div
                        className="bg-white w-max-width"
                        onClick={(e) => e.stopPropagation()}
                        style={{ borderRadius: '20px', padding: "40px" }}
                    >
                        <h2 className="modal-header me-5">{title}</h2>
                        <hr />
                        <div>{children}</div>
                    </div>
                </div>, document.getElementById("modal-root")
            )}
        </>
    );
};

export default GeneriqueModal;
