import React, { useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';
import "../DisplayJournal/style.css"
import { useEffect } from "react";
import HeaderFirst from 'utils/HeaderFirst'
import { useParams } from 'react-router-dom';
import { useGetGrandLivreGeneralQuery } from 'actions/sage_clone/comptabilite/queries';
import { usePermissions } from 'hooks/usePermissions';

import BarLoader from "react-spinners/BarLoader"

export default function GrandLivre() {

  const { clientID } = useParams();
  const permissions = usePermissions();
  const { data: grandlivre = {}, isLoading: isLoadingCode, refetch } = useGetGrandLivreGeneralQuery({
    id: 11,
    dossier: clientID
  })

  const [searchCompte, setSearchCompte] = useState(0)
  // pour excel
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Grand Livre Générale table',
    sheet: 'GrandLivre'
  })

  // pour pdf
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

  const myRef = useRef(null);


  useEffect(function () {
    tableRef.current = myRef.current;
    targetRef.current = myRef.current;
  }, [myRef.current]);

  useEffect(() => {
    refetch()
  }, [isLoadingCode, grandlivre])


  var filteredGrandLivre = []
  if (grandlivre &&
    Object.prototype.hasOwnProperty.call(grandlivre, 'grand-livre')) {
    filteredGrandLivre = grandlivre["grand-livre"]?.filter((ecriture) => {
      var ecr_string = "" + (ecriture.numero_comptable).toString()
      var searh_string = "" + searchCompte?.toString()
      console.log("Ecriture String: ", ecr_string)
      console.log("Search String: ", searh_string)
      if (searchCompte === 0 || searh_string === "") {
        return ecriture
      }
      else {
        return ecr_string.includes(searh_string)
      }

    });
  }
  console.log("Grand Livre filtered: ", filteredGrandLivre)

  return (
    <div
      className="global client-detail-container"
      style={{
        backgroundColor: 'white',
        minHeight: '500px',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      <HeaderFirst
        nom={
          <h4 className="titre-dossier">
            Grand Livre: <span style={{ color: "#009688" }}>Général</span>
          </h4>
        }
        description={
          <div>
            Vous pouvez ici visualiser l&apos;ensemble des écritures comptables de votre client.
          </div>
        }
      />
      {permissions.DOWNLOAD_ACCOUNTING_STATEMENTS && (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
          <div>
            <input type="number" onChange={(e) => setSearchCompte(e.target.value)} placeholder='Filtrer par compte' />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <button onClick={onDownload}> Export excel </button>
            <button onClick={() => toPDF()}>Download PDF</button>
          </div>
        </div>
      )}

      <table id="customers" ref={myRef}>
        <tbody>
          <tr>
            <th>Date</th>
            <th>Code Journal</th>
            <th>Libelle</th>
            <th>Mouvement Débit</th>
            <th>Mouvement Crédit</th>
          </tr>
          {!isLoadingCode?(<>
            {grandlivre &&
            Object.prototype.hasOwnProperty.call(grandlivre, 'grand-livre') &&
            filteredGrandLivre.map((livres, index) => {
              return (
                <>
                  <tr className='livre' key={index} style={{ backgroundColor: "rgba(0,0,0,0.5)", color: "white" }}>
                    <td>{livres.numero_comptable}</td>
                    <td></td>
                    <td>{livres.libelle}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  {
                    livres.elements.map((ecriture, i) => {
                      return (
                        <tr key={i}>
                          <td>{ecriture.date}</td>
                          <td>{ecriture.code}</td>
                          <td>{ecriture.libelle}</td>
                          <td>{ecriture.debit}</td>
                          <td>{ecriture.credit}</td>
                        </tr>
                      )
                    })
                  }
                  <tr key={index + 1} >
                    <td>-</td>
                    <td>-</td>
                    <td style={{ fontWeight: "bold" }}>Total</td>
                    <td style={{ fontWeight: "bold" }}>{livres.debit}</td>
                    <td style={{ fontWeight: "bold" }}>{livres.credit}</td>
                  </tr>
                </>
              )
            })
          }
          <tr>
            <th>-</th>
            <th>-</th>
            <th>Total</th>
            <th>{grandlivre &&
              Object.prototype.hasOwnProperty.call(grandlivre, 'total_debits') &&
              grandlivre["total_debits"]}
            </th>
            <th>{grandlivre &&
              Object.prototype.hasOwnProperty.call(grandlivre, 'total_credits') &&
              grandlivre["total_credits"]}
            </th>
          </tr>
          </>):(<>
            <tr className="global fournisseur-detail-container"
              style={{
                backgroundColor: 'white',
                minHeight: '700px',
                borderRadius: '20px',
                padding: '15px',
                opacity: '0.5',
                position: 'relative'
                
              }}>
                <td 
                  style={{
                    position: 'absolute',
                    top: '70%',
                    left: '50%',
                    colspan:"3"
                  }}
                >
                  <BarLoader />
                </td>
            </tr>
          </>)}


        </tbody>

      </table>
    </div>
  )
}
