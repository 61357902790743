/*function useGetCurrentUser() {
  // const user = null;
  const user = { role: "user" };

  return user;
}

export default useGetCurrentUser;*/
import {useSelector} from "react-redux";
import {getUser} from "redux/features/userSliceWithTokenValidation";

/**
 *
 * @return {true|false}
 */
function useGetCurrentUser() {
  // const isAuthenticated = true
  const userStatus = useSelector(getUser);

  //
  return userStatus;
}

export default useGetCurrentUser;
