import React, {useEffect, useState} from "react";
import "../ForderPage/folder.css";
import dossierClient from "../../image/dossierClient.png";
import Modal from "components/Modal";
import arrow from "../../image/arrow-left.png";
import {FormProvider, useForm} from "react-hook-form";
import {getUser} from "redux/features/userSliceWithTokenValidation";
import {useSelector} from "react-redux";
import HeaderButton from "utils/headerButton";
import {useNavigate} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";

import SkeletonComponent from "utils/skeleton/skeleton";
import {useGetDossierActivatedQuery} from "actions/dossiers/queries";
import {
  //useDeleteDossierMutation,
  useUpdateDossierMutation,
} from "actions/dossiers/mutations";
import GridList from "react-flexible-list";
import {usePermissions} from "hooks/usePermissions";
import {GoInbox} from "react-icons/go";
import ButtonWhite from "components/buttons/ButtonWhite";
// import { useDeleteDossierMutation } from 'actions/auth/mutations';

const ForderListPage = () => {
  const [updateDossier] = useUpdateDossierMutation();
  //const [deleteDossier] = useDeleteDossierMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const permissions = usePermissions();

  const userStatus = useSelector(getUser);
  const comptableId = userStatus?.id;
  const {data, isLoading} = useGetDossierActivatedQuery({
    comptableId,
    activated: "False",
  });
  const [doubleClickCount, setDoubleClickCount] = useState({});
  const [doubleClickTimer, setDoubleClickTimer] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  // Utilisez la fonction `filter` pour filtrer les dossiers en fonction du terme de recherche
  const filteredDossiers = data?.data?.filter(
    (dossier) =>
      dossier?.nom_entreprise
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) && !dossier?.archived
  );

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const [selectedDossier, setSelectedDossier] = useState(null);
  const handleDossierClick = (dossierId) => {
    setSelectedDossier((prevSelectedDossier) =>
      prevSelectedDossier === dossierId ? null : dossierId
    );
    if (doubleClickCount[dossierId]) {
      navigate(`/client/${dossierId}`);

      // Réinitialiser le compteur de clics pour le dossier
      setDoubleClickCount((prevCounts) => ({
        ...prevCounts,
        [dossierId]: 0,
      }));
    } else {
      // Dossier cliqué une fois, mettre à jour le compteur
      setDoubleClickCount((prevCounts) => ({
        ...prevCounts,
        [dossierId]: 1,
      }));

      // Configurer un timer pour réinitialiser le compteur après un certain délai
      const timer = setTimeout(() => {
        setDoubleClickCount((prevCounts) => ({
          ...prevCounts,
          [dossierId]: 0,
        }));
      }, 300); // Ajustez le délai selon vos besoins

      // Stocker le timer dans l'état pour pouvoir le nettoyer si nécessaire
      setDoubleClickTimer((prevTimers) => ({
        ...prevTimers,
        [dossierId]: timer,
      }));
    }
    useEffect(() => {
      // Nettoyer les timers lors du démontage du composant
      return () => {
        Object.values(doubleClickTimer).forEach(clearTimeout);
      };
    }, [doubleClickTimer]);
  };

  const handleEyesClick = () => {
    if (selectedDossier !== null) {
      navigate(`/client/${selectedDossier}`);
    }
  };

  //  const refetchData = async () => {
  //   try {
  //     // Utilisez l'async/await pour attendre la résolution de la requête de refetch
  //     const refetchResult = await refetch();

  //     // Assurez-vous que la requête de refetch s'est terminée avec succès avant de traiter les données
  //     if (refetchResult && refetchResult.status === 'fulfilled') {
  //       console.log('Refetch des données réussi');
  //     } else {
  //       console.error('Échec du refetch des données');
  //     }
  //   } catch (error) {
  //     console.error('Erreur lors du refetch des données', error);
  //   }
  // };

  // DEACTIVATION
  const handleActiveClick = async () => {
    if (selectedDossier !== null) {
      const dossierId = selectedDossier;
      try {
        // Utilisez l'async/await pour attendre la résolution de la mutation
        const res = await updateDossier({
          id: dossierId,
          body: {activated: true},
          token: userStatus?.access,
        });
        toast.success("Dossier désactivé avec succès");
        console.log("response", res);
        // Actualisez les données après l'archivage
        // refetchData();
      } catch (error) {
        console.error("Échec de désactivation du dossier", error);
      }
    } else {
      console.error("ID du dossier nest pas valide :", selectedDossier);
    }
  };

  // ARCHIVAGE
  const handleArchiveClick = async () => {
    if (selectedDossier !== null) {
      const dossierId = selectedDossier;
      try {
        // Utilisez l'async/await pour attendre la résolution de la mutation
        const res = await updateDossier({
          id: dossierId,
          body: {archived: true},
          token: userStatus?.access,
        });
        toast.success("Dossier archivé avec succès");
        console.log("response", res);
      } catch (error) {
        console.error("Échec de l'archivage du dossier", error);
      }
    } else {
      console.error("ID du dossier nest pas valide :", selectedDossier);
    }
  };

  //Delete Dossier
  /*
  const handleDeleteClick = async () => {
    if (selectedDossier !== null) {
      const dossierId = selectedDossier;

      try {
        // Utilisez l'async/await pour attendre la résolution de la mutation
        const res = await deleteDossier({
          id: dossierId,
          token: userStatus?.access,
        });
        toast.success('Dossier archivé avec succès');
        console.log('Dossier archivé avec succès', res);
        // Actualisez les données après l'archivage
        // refetchData();
      } catch (error) {
        console.error("Échec de l'archivage du dossier", error);
      }
    } else {
      console.error("L'ID du dossier n'est pas valide :", selectedDossier);
    }
  };*/

  const handleDossierCreated = () => {
    setIsModalOpen(false);
  };

  const methods = useForm();

  return (
    <div
      className="global"
      style={{
        backgroundColor: "white",
        height: "85vh",
        borderRadius: "20px",
        padding: "15px",
        overflow: "auto",
      }}>
      <ToastContainer />
      <div
        className="header"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          // padding: "13px",
        }}>
        <div
          className="header-title"
          style={{padding: "15px", flex: 1, display: "flex"}}>
          <h4 className="titre-dossier">Dossiers Clients</h4>
          <span className="nbrDossier">{filteredDossiers?.length}</span>
        </div>
        <div>
          <ButtonWhite text={"+ Créer un dossier"} onClick={openModal} />
        </div>
      </div>

      <FormProvider {...methods}>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          onDossierCreated={handleDossierCreated}>
          <h2 style={{color: " #009688", fontSize: "20px", fontWeight: 700}}>
            <span>
              {" "}
              <img src={arrow} />
            </span>{" "}
            &nbsp;CREER UN NOUVEAU DOSSIER CLIENT
          </h2>
        </Modal>
      </FormProvider>

      {permissions.ACCESS_MODIFICATION && (
        <div className="mt-4">
          <HeaderButton
            isButtonsEnabled={selectedDossier !== null}
            onEyesClick={handleEyesClick}
            onClickArchive={handleArchiveClick}
            onSearchChange={handleSearchChange}
            onActivedClick={handleActiveClick}
          />
        </div>
      )}
      <div className="mt-3">
        {isLoading ? (
          <GridList
            cardWidth={200}
            resourceData={[1, 2, 3, 4, 5]}
            resourceItem={() => <SkeletonComponent />}
          />
        ) : (
          <GridList
            cardWidth={150}
            emptyComponent={() => (
              <div className="text-center d-flex flex-column align-items-center gap-2 mt-5 text-secondary opacity-75">
                <GoInbox className="fs-1" />
                <h6 style={{fontFamily: "Montserrat"}}>Aucun dossier client</h6>
              </div>
            )}
            resourceData={filteredDossiers}
            resourceItem={(dossier) => (
              <div
                className="d-flex flex-column align-items-center carte"
                key={dossier?.id}
                onClick={() => handleDossierClick(dossier?.id)}
                style={{
                  ...monDossierParent2,
                  backgroundColor:
                    selectedDossier === dossier.id ? "#CCE8FF" : "transparent",
                  transform:
                    selectedDossier === dossier.id ? "scale(1.05)" : "none",
                  transition: "transform 0.2s ease, background-color 0.2s ease",
                }}>
                <img
                  src={dossierClient}
                  alt="Dossier Client"
                  style={monDossier}
                />
                <p style={nomDossier}>{dossier?.nom_entreprise}</p>
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};
// const monDossierParent1 = {
//   display: 'flex',
//   flexWrap: 'wrap',
//   padding: "25px",
//   marginTop: "15px"
// }
const monDossierParent2 = {
  cursor: "pointer",
  border: "none",
  background: "none",
  padding: 10,
};
const monDossier = {
  width: "90px",
  height: "70px",
};
const nomDossier = {
  fontWeight: "600",
  fontFamily: "Montserrat",
  fontSize: "11px",
  textAlign: "center",
  marginTop: "10px",
  color: "#000000",
};

export default ForderListPage;
