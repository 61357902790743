import {ApiMananger} from "actions/rtkquery";
import {
  GET_FILE_OCR_API_ROUTE,
  GET_OCR_API_ROUTE,
  GET_OCR_TO_EXTRACT,
  GET_ONE_OCR_API_ROUTE,
} from "routes/api/endpoints";

const ocrApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getOcr: build.query({
      query: () => GET_OCR_API_ROUTE,
      providesTags: ["INVALIDATE_OCR", "INVALIDATE_DOSSIER"],
    }),
    getOcrFacture: build.query({
      query: (id) => ({
        url: `${GET_FILE_OCR_API_ROUTE}?dossier_client=${id}`,
        method: "GET",
      }),
      providesTags: ["INVALIDATE_OCR", "INVALIDATE_DOSSIER"],
    }),
    getOcrId: build.query({
      query: ({id}) => `${GET_ONE_OCR_API_ROUTE}${id}`,
      providesTags: ["INVALIDATE_OCR", "INVALIDATE_DOSSIER"],
    }),
    extractOcrFacture: build.mutation({
      query: (formData) => ({
        url: GET_OCR_TO_EXTRACT,
        method: "GET",
        body: formData,
      }),
      invalidatesTags: ["INVALIDATE_OCR"],
    }),
    extractOcrData: build.query({
      query: (id) => ({
        url: `/ocr_extraction/${id}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  //dossier
  useGetOcrQuery,
  useExtractOcrFactureMutation,
  useGetOcrFactureQuery,
  useGetOcrIdQuery,
  useExtractOcrDataQuery,
  useLazyExtractOcrDataQuery,
} = ocrApi;
