import React from 'react'
import BarLoader from "react-spinners/BarLoader"

export default function Bilan_Actif({bilan_comptable,isLoadingCode}) {

      
  return (
    <>
          <tr>
            <th>Libelle de quelque chose</th>
            <th>Brut</th>
            <th>Amortissement</th>
            <th>Net</th>
            <th>Exercice(N-1)</th>
          </tr>
          {
            !isLoadingCode? (<>
            {/**************************************************** IMMOBILISATIONS ************************************************ */}
          <tr>
            <td style={{fontWeight:"bold"}}>IMMOBILISATIONS INCORPORELLES</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?
          Object.keys(bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS INCORPORELLES"]).map((title,index) =>{
            return(
                <tr key={index}>
                  <td>{title}</td>
                  <td>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS INCORPORELLES"][title]["brut"]}</td>
                  <td>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS INCORPORELLES"][title]["amortissement"]}</td>
                  <td>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS INCORPORELLES"][title]["net"]}</td>
                  <td>-</td>
                </tr>
            )
          }):''}

          <tr>
            <td style={{fontWeight:"bold"}}>IMMOBILISATIONS CORPORELLES</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?
          Object.keys(bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS CORPORELLES"]).map((title,index) =>{
            return(
                <tr key={index}>
                  <td>{title}</td>
                  <td>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS CORPORELLES"][title]["brut"]}</td>
                  <td>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS CORPORELLES"][title]["amortissement"]}</td>
                  <td>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS CORPORELLES"][title]["net"]}</td>
                  <td>-</td>
                </tr>
            )
          }):""}

          <tr>
            <td style={{fontWeight:"bold"}}>IMMOBILISATIONS FINANCIERES</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?
          Object.keys(bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS FINANCIERES"]).map((title,index) =>{
            return(
                <tr key={index}>
                  <td>{title}</td>
                  <td>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS FINANCIERES"][title]["brut"]}</td>
                  <td>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS FINANCIERES"][title]["amortissement"]}</td>
                  <td>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["IMMOBILISATIONS FINANCIERES"][title]["net"]}</td>
                  <td>-</td>
                </tr>
            )
          }):''}

        {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?
          <tr>
            <th>Total Immobilisation</th>
            <th>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["Total ACTIF IMMOBILISE"]["brut"]}</th>
            <th>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["Total ACTIF IMMOBILISE"]["amortissement"]}</th>
            <th>{bilan_comptable["ACTIF"]["ACTIF IMMOBILISE"]["Total ACTIF IMMOBILISE"]["net"]}</th>
            <th>-</th>
          </tr>:<tr></tr>}
          {/******************************************************************************************************************** */}

          {/**************************************************** ACTIF CIRCULANT ************************************************ */}
          <tr>
            <td style={{fontWeight:"bold"}}>ACTIF CIRCULANT</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?
          Object.keys(bilan_comptable["ACTIF"]["ACTIF CIRCULANT"]).map((title,index) =>{
            if(!title.includes("Total")){
              return(
                  <tr key={index}>
                    <td>{title}</td>
                    <td>{bilan_comptable["ACTIF"]["ACTIF CIRCULANT"][title]["brut"]}</td>
                    <td>{bilan_comptable["ACTIF"]["ACTIF CIRCULANT"][title]["amortissement"]}</td>
                    <td>{bilan_comptable["ACTIF"]["ACTIF CIRCULANT"][title]["net"]}</td>
                    <td>-</td>
                  </tr>
              )
            }
          }):''}
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?
          <tr>
            <th>Total ACTIF CIRCULANT</th>
            <th>{bilan_comptable["ACTIF"]["ACTIF CIRCULANT"]["Total ACTIF CIRCULANT"]["brut"]}</th>
            <th>{bilan_comptable["ACTIF"]["ACTIF CIRCULANT"]["Total ACTIF CIRCULANT"]["amortissement"]}</th>
            <th>{bilan_comptable["ACTIF"]["ACTIF CIRCULANT"]["Total ACTIF CIRCULANT"]["net"]}</th>
            <th>-</th>
          </tr>:<></>}
          {/********************************************************************************************************************* */}

          {/**************************************************** TRESORERIE ACTIVE ************************************************ */}
          <tr>
            <td style={{fontWeight:"bold"}}>TRESORERIE ACTIF</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?
          Object.keys(bilan_comptable["ACTIF"]["TRESORERIE ACTIF"]).map((title,index) =>{
            return(
                <tr key={index}>
                  <td>{title}</td>
                  <td>{bilan_comptable["ACTIF"]["TRESORERIE ACTIF"][title]["brut"]}</td>
                  <td>{bilan_comptable["ACTIF"]["TRESORERIE ACTIF"][title]["amortissement"]}</td>
                  <td>{bilan_comptable["ACTIF"]["TRESORERIE ACTIF"][title]["net"]}</td>
                  <td>-</td>
                </tr>
            )
          }):''}
          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?
          <tr>
            <th>Total TRESORERIE ACTIF</th>
            <th>{bilan_comptable["ACTIF"]["TRESORERIE ACTIF"]["Total TRESORERIE ACTIF"]["brut"]}</th>
            <th>{bilan_comptable["ACTIF"]["TRESORERIE ACTIF"]["Total TRESORERIE ACTIF"]["amortissement"]}</th>
            <th>{bilan_comptable["ACTIF"]["TRESORERIE ACTIF"]["Total TRESORERIE ACTIF"]["net"]}</th>
            <th>-</th>
          </tr>:<tr></tr>}

          {/*********************************************************************************************************************** */}

          {
            bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?(
              <tr>
                <td style={{fontWeight:"bold"}}>Ecart de conversion-Actif</td>
                <td>{bilan_comptable["ACTIF"]["Ecart de conversion-Actif"]["brut"]}</td>
                <td>{bilan_comptable["ACTIF"]["Ecart de conversion-Actif"]["amortissement"]}</td>
                <td>{bilan_comptable["ACTIF"]["Ecart de conversion-Actif"]["net"]}</td>
                <td>-</td>
              </tr>
            ):""
          }

          {bilan_comptable && Object.prototype.hasOwnProperty.call(bilan_comptable,'ACTIF')?
            <tr>
              <th>Total ACTIF</th>
              <th>{bilan_comptable["ACTIF"]["Total Actif"]["brut"]}</th>
              <th>{bilan_comptable["ACTIF"]["Total Actif"]["amortissement"]}</th>
              <th>{bilan_comptable["ACTIF"]["Total Actif"]["net"]}</th>
              <th>-</th>
            </tr>:""
            }
            </>):(
              <tr className="global fournisseur-detail-container"
                style={{
                  backgroundColor: 'white',
                  minHeight: '700px',
                  borderRadius: '20px',
                  padding: '15px',
                  opacity: '0.5',
                  position: 'relative'
                  
                }}>
                  <td 
                    style={{
                      position: 'absolute',
                      top: '70%',
                      left: '50%',
                      colspan:"3"
                    }}
                  >
                    <BarLoader />
                  </td>
              </tr>)
          }
    </>
  )
}
